import React from "react";
import Button from "../../Button/Button";
import Text from "../../Text/Text";
import { connectorLocalStorageKey } from "./config";
import { Login, Config } from "./types";

interface Props {
  walletConfig: Config;
  login: Login;
  onDismiss: () => void;
  mb: string;
}

const WalletCard: React.FC<Props> = ({
  login,
  walletConfig,
  onDismiss,
  mb,
}) => {
  const { title, icon: Icon } = walletConfig;
  return (
    <Button
      variant="tertiary"
      onClick={() => {
        login(walletConfig.connectorId);
        window.localStorage.setItem(
          connectorLocalStorageKey,
          walletConfig.connectorId
        );
        onDismiss();
      }}
      style={{
        justifyContent: "space-between",
        width: "100%",
        marginBottom: mb,
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Text bold style={{ color: "#fff", marginRight: "16px" }}>
        {title}
      </Text>
      <Icon width="32px" />
    </Button>
  );
};

export default WalletCard;

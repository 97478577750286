import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getFullDisplayBalance } from "../../../utils/formatBalance";
import { useAppDispatch } from "../../../state";
import { fetchReferrals } from "../../../state/teams";
const NUMBER_OF_REFERRALS_VISIBLE = 5;

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

function Members(props: any) {
  const { users, referralAddresses, referralList } = props;
  const dispatch = useAppDispatch();

  function loadMore() {
    let addressList = referralAddresses.slice(
      referralList.length,
      referralAddresses.length
    );
    dispatch(fetchReferrals(addressList.slice(0, NUMBER_OF_REFERRALS_VISIBLE)));
  }

  return (
    <div>
      {referralAddresses.map(
        (item, index) =>
          referralList[index] !== undefined && (
            <div className="member-item" key={index}>
              <div className="member-item-num">
                <div>{index + 1}</div>
              </div>
              <div className="member-item-info">
                <div style={{ flexGrow: 1 }}>
                  <div>
                    <b>{item}</b>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <span>
                      Total Staked: $
                      {getFullDisplayBalance(referralList[index].points, 18, 2)}
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      Member: {referralList[index].users}
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      Team Investment: $
                      {getFullDisplayBalance(
                        referralList[index].totalFundSubTeam,
                        18,
                        2
                      )}
                    </span>
                  </div>
                </div>
                <Link to={`/teams/${item}`} className="member-item-see-more">
                  <Button {...activeButtonStyle}>See More</Button>
                </Link>
              </div>
            </div>
          )
      )}
      <div style={{ marginBottom: "50px", textAlign: "center" }}>
        {users > referralList.length && (
          <Button {...activeButtonStyle} onClick={loadMore}>
            Load more...
          </Button>
        )}
      </div>
    </div>
  );
}

export default Members;

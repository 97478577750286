import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { stakeCloud } from "../../utils/callHelpers";
import { getCloudStakingContract } from "../../utils/contractHelpers";
import useWeb3 from "../useWeb3";
import { bnHelper } from "src/utils/bignumber-helper";

export const useStakeToken = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getCloudStakingContract(web3);

  const handleStake = useCallback(
    async (pid, amount) => {
      const txHash = await stakeCloud(
        account,
        contract,
        pid,
        bnHelper.toDecimalString(amount)
      );
      return txHash;
    },
    [account, contract]
  );

  return { onStake: handleStake };
};

import { useEffect, useMemo } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./";
import useRefresh from "../hooks/useRefresh";
import { State, ProfileState, TeamsState } from "./types";
import { fetchTeams } from "./teams";

// Teams
export const useTeam = (account: string) => {
  const { isInitialized, isLoading, data }: TeamsState = useSelector(
    (state: State) => state.teams
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchTeams(account));
    }
  }, [account, dispatch]);

  return { teams: data, isInitialized, isLoading };
};

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block);
};

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock);
};

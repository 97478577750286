import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import teamsReducer from "./teams";
import blockReducer from "./block";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    block: blockReducer,
    teams: teamsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useApproveForAll } from "src/hooks/useApproveForAll";

import { useCloudTokenPrice } from "src/hooks/useCloudTokenPrice";
import { useGetApprovedForAll } from "src/hooks/useGetApprovedForAll";
import { useSellNFT } from "src/hooks/useSellNFT";

interface INFTSellModal {
  isOpen: boolean;
  onClose: () => void;
  item: any;
}

function SellModal(props: INFTSellModal) {
  const { isOpen, onClose, item } = props;
  const cloudTokenPrice = useCloudTokenPrice();
  const approvedForAll = useGetApprovedForAll();
  const { onSellNFT } = useSellNFT();
  const { onApproveForAll } = useApproveForAll();

  const [requestedApproval, setRequestedApproval] = useState(false);

  const handleSellNFT = useCallback(
    async (tokenId) => {
      try {
        // setRequestedApproval(true);
        await onSellNFT(tokenId);
        onClose();
        toast.success("Successfully sell your NFT!", { autoClose: 3000 });
        // setRequestedApproval(false);
      } catch (e) {
        // setRequestedApproval(false);
        console.error(e);
      }
    },
    [onSellNFT]
  );

  const approveForAll = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApproveForAll();
      // onClose();
      toast.success("Successfully approve your NFT!", { autoClose: 3000 });
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onSellNFT]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        width="500px"
        maxWidth="none"
        borderRadius="20px"
        overflow="hidden"
      >
        <ModalHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ lineHeight: "30px" }}>
            Sell NFT <b style={{ color: "#FFA800" }}>#{item.tokenId}</b>
          </span>
          <span
            style={{
              fontSize: "14px",
              lineHeight: "30px",
              color: {
                Common: "#938FF2",
                Rare: "#01B976",
                Legendary: "#FFA800",
              }[item.rank],
            }}
          >
            {item.rank}
          </span>
        </ModalHeader>
        <ModalBody style={{ borderTop: "1px solid #e2e8f0" }}>
          <div
            style={{
              maxWidth: "350px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "rgba(24, 31, 81, 0.05)",
                borderRadius: "10px",
                padding: "8px 20px",
              }}
            >
              <span style={{ fontSize: "14px", lineHeight: "35px" }}>
                Base Price
              </span>
              <span
                style={{
                  fontSize: "20px",
                  lineHeight: "35px",
                  fontWeight: "500",
                }}
              >
                {item.price / 1e18} USDT
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "rgba(24, 31, 81, 0.05)",
                borderRadius: "10px",
                padding: "8px 20px",
              }}
            >
              <span style={{ fontSize: "14px", lineHeight: "35px" }}>
                Receive
              </span>
              <span
                style={{
                  fontSize: "20px",
                  lineHeight: "35px",
                  fontWeight: "500",
                }}
              >
                {(((item.price / 1e10) * 0.75) / cloudTokenPrice).toFixed(2)}{" "}
                CLOUD
              </span>
            </div>
            {approvedForAll ? (
              <Button
                background="global.yellowOrange"
                style={{ borderRadius: "10px", fontSize: "14px" }}
                onClick={() => {
                  handleSellNFT(item.tokenId);
                }}
              >
                Instant Sell
              </Button>
            ) : requestedApproval ? (
              <Button disabled style={{ cursor: "default" }}>
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                  style={{ color: "#181F51" }}
                ></div>
              </Button>
            ) : (
              <Button
                background="global.yellowOrange"
                style={{ borderRadius: "10px", fontSize: "14px" }}
                onClick={approveForAll}
                disabled={requestedApproval}
              >
                Approve
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SellModal;

import {
  getReferralContract,
  getStakingContract,
} from "../../utils/contractHelpers";
import { TeamData, TeamState } from "../types";
const referralContract = getReferralContract();
const stakingContract = getStakingContract();
const NUMBER_OF_REFERRALS_VISIBLE = 5;

// eslint-disable-next-line import/prefer-default-export
export const getTeams = async (account): Promise<TeamState> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  try {
    const teamAdds = await referralContract.methods.getTeam(account).call();
    const totalFund = await referralContract.methods.totalFund(account).call();
    const totalCommission = await referralContract.methods
      .totalReferralCommissions(account)
      .call();
    const referrer = await referralContract.methods.referrers(account).call();
    const isVip = true;

    const teamData = [];
    for (
      let i = 0;
      i < teamAdds.length && i < NUMBER_OF_REFERRALS_VISIBLE;
      i++
    ) {
      // eslint-disable-next-line no-await-in-loop
      const nbSubTeams = await referralContract.methods
        .getTeam(teamAdds[i])
        .call();
      // eslint-disable-next-line no-await-in-loop
      const totalFundSubTeam = await referralContract.methods
        .totalFund(teamAdds[i])
        .call();
      // eslint-disable-next-line no-await-in-loop
      const totalStaked = await stakingContract.methods
        .totalStake(teamAdds[i])
        .call();
      teamData[i] = {
        name: teamAdds[i],
        users: nbSubTeams.length,
        points: totalStaked,
        totalFundSubTeam,
      };
    }
    return {
      name: account,
      address: account,
      users: teamAdds.length,
      totalCommission,
      points: totalFund,
      referralAddresses: teamAdds,
      referralList: teamData,
      owner: referrer,
      isVip,
    };
  } catch (error) {
    console.info(error);
    return null;
  }
};

export const getMoreReferrals = async (accounts): Promise<Array<TeamData>> => {
  try {
    const referralsData = [];
    for (let i = 0; i < accounts.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const nbSubTeams = await referralContract.methods
        .referralsCount(accounts[i])
        .call();
      // eslint-disable-next-line no-await-in-loop
      const totalFundSubTeam = await referralContract.methods
        .totalFund(accounts[i])
        .call();
      // eslint-disable-next-line no-await-in-loop
      const totalStaked = await stakingContract.methods
        .totalStake(accounts[i])
        .call();

      referralsData[i] = {
        name: accounts[i],
        users: nbSubTeams.length,
        points: totalStaked,
        totalFundSubTeam,
      };
    }
    return referralsData;
  } catch (error) {
    return [];
  }
};

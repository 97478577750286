/* eslint-disable react/function-component-definition */
import React from "react";
import { Text, Grid, GridItem, VStack } from "@chakra-ui/react";
import { getFullDisplayBalance } from "src/utils/formatBalance";

const Item = ({ title, value, sign, w }: any) => {
  const isFull = w === "full" ? { gridColumn: ["1 / -1", "auto", "auto"] } : {};
  return (
    <GridItem w="100%" {...isFull}>
      <VStack
        padding="10px"
        background="linear-gradient(0deg, rgba(24, 31, 81, 0.05), rgba(24, 31, 81, 0.05)), #FFFFFF"
        borderRadius="20px"
      >
        <Text
          fontFamily="Poppins, sans-serif"
          color="#181F51"
          fontSize={["12px", "12px", "16px"]}
          fontWeight={700}
          lineHeight="18px"
          align="center"
        >
          {title}
        </Text>
        <Text
          fontFamily="Poppins, sans-serif"
          color="#181F51"
          fontSize={["32px", "32px", "40px"]}
          fontWeight={500}
          lineHeight="48px"
        >
          {value}
        </Text>
        <Text
          fontFamily="Poppins, sans-serif"
          color="#181F51"
          fontSize={["14px", "14px", "18px"]}
          fontWeight={700}
          lineHeight="21px"
        >
          {sign}
        </Text>
      </VStack>
    </GridItem>
  );
};

function Info(props: any) {
  const {
    activeMembers,
    nftBalance,
    nftStaking,
    nftValue,
    teamInvestment,
    commissionEarned,
  } = props;
  return (
    <Grid
      w="full"
      background="#FFFFFF"
      borderRadius="40px"
      gap={6}
      templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(6, 1fr)"]}
      padding={["20px", "20px", "30px"]}
    >
      <Item title="Active Members" value={activeMembers} sign="Members" />
      <Item
        title="Team Investment"
        value={`$${getFullDisplayBalance(teamInvestment, 18, 2)}`}
        sign="USD"
      />
      <Item
        title="Commission Earned"
        value={`$${getFullDisplayBalance(commissionEarned, 18, 2)}`}
        sign="USD"
        w="full"
      />
      <Item title="Your NFT" value={nftBalance} sign="NFT" />
      <Item title="Your NFT Staking" value={nftStaking} sign="NFT" />
      <Item
        title="Your NFT Value"
        value={`$${getFullDisplayBalance(nftValue)}`}
        sign="USD"
        w="full"
      />
    </Grid>
  );
}

export default Info;

import React from "react";
import { HStack, Image } from "@chakra-ui/react";
import NFTImage3 from "src/assets/NFT3.png";
import { Button } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import StakeModal from "./StakeModal";
import WithdrawModal from "./WithdrawModal";
import BigNumber from "bignumber.js";
import pools from "../pools.json";
import { useHarvest } from "src/hooks/stakeToken/useHarvest";
import { useState } from "react";
import { useAllowance } from "src/hooks/useAllowance";
import { CLOUD_TOKEN, STAKING_TOKEN_CONTRACT } from "src/config";

export default function Pool(props: any) {
  const {
    isOpen: isOpenStakeModal,
    onOpen: onOpenStakeModal,
    onClose: onCloseStakeModal,
  } = useDisclosure();

  const {
    isOpen: isOpenWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const allowance = useAllowance(CLOUD_TOKEN, STAKING_TOKEN_CONTRACT);
  const { id, staked, earned, setReloadTime } = props;
  const { onHarvest } = useHarvest();

  const [harvestLoading, setHarvestLoading] = useState(false);

  const handleHarvest = async () => {
    setHarvestLoading(true);
    try {
      await onHarvest(id);
      setReloadTime(new Date().getTime());
    } catch (e) {
      console.log(e);
    }
    setHarvestLoading(false);
  };

  return (
    <>
      <HStack
        width="332px"
        minHeight="416px"
        height="auto"
        background="#fff"
        color="#000"
        borderRadius="20px"
        // position="relative"
        // padding="12px"
        display="flex"
        flexDirection="column"
        border="1px solid #ccc"
        margin="0 !important"
      >
        <div
          style={{
            background: "linear-gradient(111.68deg,#f2ecf2,#e8f2f6)",
            width: "100%",
            borderRadius: "20px 20px 0 0",
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div style={{ fontSize: "24px" }}>Earn Cloud</div>
            <div style={{ fontSize: "14px" }}>Stake Cloud</div>
          </div>
          <div>
            <Image src={NFTImage3} />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            padding: "20px",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: "14px" }}>APR</div>
            <div>{pools[id].apr}%</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: "14px" }}>Locking Time</div>
            <div>{pools[id].lockingTime} months</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={{ fontSize: "14px" }}>CLOUD Earned</div>
              <div>
                <b>{earned}</b>
              </div>
            </div>
            <div>
              <Button
                style={{ fontWeight: "400" }}
                onClick={handleHarvest}
                isDisabled={harvestLoading}
              >
                Harvest
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div style={{ fontSize: "14px" }}>CLOUD Staked</div>
              <div>
                <b>{staked}</b>
              </div>
            </div>
            <div>
              <Button
                style={{ fontWeight: "400" }}
                onClick={() => {
                  onOpenWithdrawModal();
                }}
              >
                Withdraw
              </Button>
            </div>
          </div>
          <div>
            <Button
              style={{
                width: "100%",
                fontWeight: "400",
                cursor: "not-allowed",
                opacity: "0.4",
                background: "#FFB524",
              }}
              // backgroundColor="global.yellowOrange"
              onClick={() => {
                // onOpenStakeModal();
              }}
              disabled
            >
              Stake
            </Button>
          </div>
        </div>
      </HStack>
      <StakeModal
        id={id}
        title="Stake Cloud"
        isOpen={isOpenStakeModal}
        onOpen={onOpenStakeModal}
        onClose={onCloseStakeModal}
        allowance={allowance}
        setReloadTime={setReloadTime}
      />
      <WithdrawModal
        id={id}
        title="Withdraw"
        isOpen={isOpenWithdrawModal}
        onOpen={onOpenWithdrawModal}
        onClose={onCloseWithdrawModal}
        staked={staked}
        setReloadTime={setReloadTime}
      />
    </>
  );
}

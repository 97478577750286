import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { sellNFT } from "../utils/callHelpers";
import { getSellNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useSellNFT = () => {
  const { account } = useWeb3React();

  const web3 = useWeb3();
  const contract = getSellNFTContract(web3);

  const handleSell = useCallback(
    async (tokenId) => {
      const txHash = await sellNFT(contract, tokenId, account);
      return txHash;
    },
    [account, contract]
  );

  return { onSellNFT: handleSell };
};

import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { TransparentHeader } from "src/components/common/Header";

import mainBackgroundImage from "src/assets/cloud.png";

import PageHeader from "./components/PageHeader";
import MainTab from "./components/MainTab";
import Calculator from "./components/Calculator";

function StakePage() {
  const [hideCalculator, setHideCalculator] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [getNftTime, setNftTime] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [stakingPool, setStakingPool] = React.useState("Ordinary");
  const forceUpdate: () => void = React.useState({})[1].bind(null, {});

  const _setSelectedItems = (data) => {
    setSelectedItems(data);
    forceUpdate();
  };

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed",
        backgroundPosition: "50% 100%",
      }}
      overflowX="hidden"
      // overflowY={["auto", "auto", "hidden"]}
    >
      <TransparentHeader isFixed isDark />
      <Stack
        paddingTop="130px"
        paddingX="30px"
        // height="100%"
        direction="column"
        color="white"
        // opacity="0.2"
        // userSelect="none"
        // pointerEvents="none"
        paddingBottom="50px"
      >
        <PageHeader />
        <Stack
          margin="30px"
          marginTop="50px !important"
          background={[
            "none",
            "none",
            hideCalculator
              ? "rgba(196, 135, 18, 0.4)"
              : "rgba(24, 31, 81, 0.4)",
          ]}
          boxShadow={
            hideCalculator
              ? ""
              : [
                  "none",
                  "none",
                  "-6px 0px 4px rgba(255, 255, 255, 0.25) !important;",
                ]
          }
          backdropFilter="blur(40px)"
          borderRadius="40px"
          padding={["0px", "0px", "30px"]}
          width="100%"
          direction={["column", "column", "row"]}
          gap="30px"
        >
          <MainTab
            setHideCalculator={setHideCalculator}
            selectedItems={selectedItems}
            setSelectedItems={_setSelectedItems}
            setItems={setItems}
            setStakingPool={setStakingPool}
            getNftTime={getNftTime}
          />
          {hideCalculator && (
            <Calculator
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              items={items}
              stakingPool={stakingPool}
              setNftTime={setNftTime}
            />
          )}
        </Stack>
      </Stack>
      {/* <div
        style={{
          fontSize: "40px",
          position: "fixed",
          top: "50%",
          width: "100%",
          textAlign: "center",
          color: "#FFB524",
        }}
      >
        Coming soon...
      </div> */}
    </Box>
  );
}

export default StakePage;

import React from "react";

import {
  Box,
  Text,
  HStack,
  VStack,
  Grid,
  GridItem,
  Button,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

import moment from "moment";

import SmallItem from "./SmallItem";
import { useStake } from "src/hooks/useStake";

const getTotalNFTValue = (items, selectedItems) => {
  let total = 0;
  for (let i = 0; i < selectedItems.length; i++) {
    if (items[selectedItems[i]] !== undefined) {
      total = total + items[selectedItems[i]].price / 1e18;
    }
  }
  return total;
};

export default function Calculator(props) {
  const { selectedItems, setSelectedItems, items, stakingPool, setNftTime } =
    props;
  const totalValue = getTotalNFTValue(items, selectedItems);
  const [stakingInProcess, setStakingInProcess] = React.useState(false);
  const { onStake } = useStake();

  return (
    <VStack
      w={["100%", "100%", "30%"]}
      height="auto"
      alignSelf="flex-start"
      borderRadius="22px"
      background="white"
      order={["2", "2", "2"]}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        padding={["20px 12px", "20px 12px", "20px 30px"]}
        background="#181F51"
        w="full"
        borderTopRadius="20px"
      >
        <Text
          color="#FFB524"
          fontFamily="Clash Display, sans-serif"
          fontWeight={700}
          fontSize="24px"
          lineHeight="140%"
        >
          Stake Calculator
        </Text>
      </Box>
      <VStack
        background="rgba(24, 31, 81, 0.05)"
        padding={["20px 12px", "20px 12px", "20px 30px"]}
        w="full"
        mt="0 !important"
      >
        <HStack w="full" justifyContent="space-between">
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="14px"
            lineHeight="21px"
          >
            {selectedItems.length} items
          </Text>
          <Text
            color="#FF4E63"
            fontFamily="Poppins, sans-serif"
            fontWeight={500}
            fontSize="14px"
            lineHeight="21px"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedItems([]);
            }}
          >
            Clear All
          </Text>
        </HStack>
        <Grid
          w="100%"
          templateColumns={[
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
            "repeat(4, 1fr)",
          ]}
          gap={2}
          minH="150px"
        >
          {selectedItems.map((item, index) => (
            <GridItem w="100%" key={index}>
              <SmallItem item={items[item]} />
            </GridItem>
          ))}
        </Grid>
      </VStack>
      <VStack w="full" padding={["20px 12px", "20px 12px", "20px 30px"]}>
        <Box
          w="full"
          padding="12px"
          border="1px solid #F3F5F7"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
          borderRadius="10px"
        >
          <Text
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="16px"
            lineHeight="21px"
            color="#181F51"
          >
            Total: {selectedItems.length} item
          </Text>
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              Total NFT value
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              ${totalValue}
            </Text>
          </HStack>
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              Duration
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              2 Year
            </Text>
          </HStack>
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              Unlock date
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              {moment().add(2, "year").format("DD/MM/yyyy")}
            </Text>
          </HStack>
        </Box>
        <Box
          w="full"
          padding="12px"
          border="1px solid #F3F5F7"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
          borderRadius="10px"
        >
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              APY
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              {stakingPool === "Ordinary" ? 72 : 96}%
            </Text>
          </HStack>
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              Daily APY
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              {stakingPool === "Ordinary" ? 0.197 : 0.263}%
            </Text>
          </HStack>
          <HStack w="100%" justifyContent="space-between" mt="0 !important">
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
              color="rgba(24, 31, 81, 0.4)"
            >
              Total earn
            </Text>
            <Text
              color="#181F51"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="12px"
              lineHeight="21px"
            >
              CLOUD ($
              {(totalValue * (stakingPool === "Ordinary" ? 6 : 8) * 2) / 100})
            </Text>
          </HStack>
        </Box>
      </VStack>
      <Box
        w="full"
        padding={[
          "0px 12px 12px 12px",
          "0px 12px 12px 12px",
          "0px 20px 20px 20px",
        ]}
      >
        <Button
          height="54px"
          fontFamily="Poppins, sans-serif"
          fontWeight={700}
          fontSize="16px"
          color="#181F51"
          background="#FFB524"
          borderRadius="8px"
          w="full"
          disabled={stakingInProcess || selectedItems.length === 0}
          onClick={async () => {
            setStakingInProcess(true);
            try {
              await onStake(selectedItems);
              setStakingInProcess(false);
              setSelectedItems([]);
              setNftTime(new Date().getTime());
            } catch (e) {
              console.log(e);
              setStakingInProcess(false);
            }
          }}
        >
          {stakingInProcess ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
              style={{ color: "#181F51" }}
            ></div>
          ) : (
            <>Stake</>
          )}
        </Button>
      </Box>
    </VStack>
  );
}

import { FC, useEffect, useRef } from "react";
import styles from "./style.module.css";

import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import { ReactComponent as ExpandDarkIcon } from "../../assets/icons/expand-dark.svg";
import { ReactComponent as DupplicateIcon } from "../../assets/icons/duplicate-sharp.svg";
import { ReactComponent as DupplicateDarkIcon } from "../../assets/icons/duplicate-sharp-dark.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/save.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as RecentIcon } from "../../assets/icons/recent.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";

const actionType = {
  HIGHER: "higher",
  CREATE: "create",
};

const listImageNumber = [1, 2, 3, 4];

export interface ImageData {
  image: string;
  imageName: string;
  components: any[];
  messageId: string;

  keyWord: string;
}
interface generatedImageProps {
  action: string;
  imageData: ImageData;
  isHighered: boolean;
  openHistoryMobile: boolean;
  selected: number;
  openModalImg: boolean;
  processAction: () => void;
  cantProcess: () => boolean;
  setSelected: (x: number) => void;
  onChangeOpenHistoryMobile: (data: boolean) => any;
  handleHigher: () => void;
  handleCreate: () => void;
  saveImage: () => void;
  setOpenModalImg: (x: boolean) => void;

  copyPrompt: (x: any) => void;
}

const Image2: FC<generatedImageProps> = ({
  action,
  imageData,
  isHighered,
  openHistoryMobile,
  selected,
  openModalImg,
  processAction,
  cantProcess,
  setSelected,
  onChangeOpenHistoryMobile,
  handleHigher,
  handleCreate,
  saveImage,
  setOpenModalImg,
  copyPrompt,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        openModalImg &&
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setOpenModalImg(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openModalImg, setOpenModalImg, popupRef]);
  return (
    <div className={`center-container w-full ${styles.mt30}`}>
      <div>
        <div className={styles.gridContainerSingle}>
          <img src={imageData.image} className={styles.image} alt="" />

          {action !== "" && (
            <div className={styles.bgHover}>
              <div className={`${styles.hoverAction} `}>
                <div
                  className={`${styles.buttonContainer}`}
                  onClick={processAction}
                >
                  <div
                    className={`${styles.buttonCustom} ${
                      !cantProcess() ? styles.yellowHover : "c-not-allow"
                    }`}
                  >
                    Process
                  </div>
                </div>

                {!isHighered && (
                  <div className={styles.orderProcess}>
                    {listImageNumber.map((o, i) => (
                      <div
                        className={styles.buttonContainer}
                        key={i}
                        onClick={() => {
                          setSelected(o);
                        }}
                      >
                        <div
                          className={`${styles.buttonCustom} ${
                            selected === o ? styles.yellowHover : ""
                          }`}
                        >
                          {o}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={`center-container ${styles.mt30} ${styles.actions}`}>
          <div
            className={`${styles.actionItem} c-pointer ${
              action === actionType.HIGHER ? styles.bgYellow : ""
            }`}
            onClick={handleHigher}
          >
            <ExpandDarkIcon
              className={`${styles.iconDark} ${
                action === actionType.HIGHER && "block"
              }`}
            />

            <ExpandIcon
              className={`${styles.iconDefault} ${
                action === actionType.HIGHER && "d-none"
              }`}
            />

            <span>Higher Resolution</span>
          </div>
          <div
            className={`${styles.actionItem} c-pointer ${
              action === actionType.CREATE ? styles.bgYellow : ""
            }`}
            onClick={handleCreate}
          >
            <DupplicateDarkIcon
              className={`${styles.iconDark} ${
                action === actionType.CREATE && "block"
              }`}
            />

            <DupplicateIcon
              className={`${styles.iconDefault} ${
                action === actionType.CREATE && "d-none"
              }`}
            />

            <span>Create Variations</span>
          </div>
          <div className={styles.iconActions}>
            <div
              className={styles.recentBtn}
              onClick={() => {
                onChangeOpenHistoryMobile(!openHistoryMobile);
              }}
            >
              <RecentIcon />
              <div>Recent</div>
            </div>
            <SaveIcon className="c-pointer" onClick={saveImage} />
            <div className={`relative flex`} ref={popupRef}>
              {window.innerWidth > 414 ? (
                <InfoIcon
                  className={`${openModalImg && styles.infoClick} c-pointer`}
                  onClick={() => {
                    setOpenModalImg(!openModalImg);
                  }}
                />
              ) : action !== "" ? (
                <InfoIcon
                  className={`${openModalImg && styles.infoClick} c-pointer`}
                  onClick={() => {
                    setOpenModalImg(!openModalImg);
                  }}
                />
              ) : (
                ""
              )}
              {openModalImg && (
                <div className={styles.popupContainer}>
                  <div
                    className={`${styles.modalInfo} center flex-col text-center`}
                  >
                    <div className={styles.modalInfoContent}>
                      {imageData.keyWord || ""}
                    </div>
                    <div
                      className={`${styles.btnCopy} center p-8-16 c-pointer`}
                      onClick={() => copyPrompt(imageData.keyWord)}
                    >
                      <span
                        className={styles.iconCopy}
                        onClick={() => {
                          copyPrompt(imageData.keyWord);
                        }}
                      ></span>
                      <CopyIcon
                        className={styles.iconCopyMobile}
                        onClick={() => {
                          copyPrompt(imageData.keyWord);
                        }}
                      />
                      <div>Copy Prompt</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {action !== "" ? (
          <div className={styles.processMobile}>
            <div className={`${styles.hoverActionMobile} `}>
              {!isHighered && (
                <div className={styles.orderProcess}>
                  {listImageNumber.map((o, i) => (
                    <div
                      className={styles.buttonContainer}
                      key={i}
                      onClick={() => {
                        setSelected(o);
                      }}
                    >
                      <div
                        className={`${styles.buttonCustomMobile} ${
                          selected === o ? styles.yellowHover : ""
                        }`}
                      >
                        {o}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div
                className={`${styles.buttonContainer}`}
                onClick={processAction}
              >
                <div
                  className={`${styles.buttonCustomMobile} ${
                    !cantProcess() ? styles.yellowHover : "c-not-allow"
                  }`}
                >
                  Process
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.imageKeyword}>{imageData.keyWord}</div>
        )}
      </div>
    </div>
  );
};

export default Image2;

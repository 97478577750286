import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Input,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import pools from "../pools.json";
import useTokenBalance from "src/hooks/useTokenBalance";
import { CLOUD_TOKEN, STAKING_TOKEN_CONTRACT } from "src/config";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { useStakeToken } from "src/hooks/stakeToken/useStakeToken";
import { useApprove } from "src/hooks/useApprove";

interface Props {
  id: number;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  title: string;
  allowance: any;
  setReloadTime: any;
}

function CustomModal(props: Props) {
  const { id, isOpen, onClose, title, allowance, setReloadTime } = props;
  const [requestedApproval, setRequestedApproval] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [amount, setAmount] = useState(0);
  const balance = useTokenBalance(CLOUD_TOKEN).balance;
  const { onStake } = useStakeToken();
  const { onApprove } = useApprove(CLOUD_TOKEN);

  const handleClose = () => {
    setAmount(0);
    onClose();
  };

  const handleStake = async () => {
    setRequestedApproval(true);
    try {
      await onStake(id, amount);
      setReloadTime(new Date().getTime());
    } catch (e) {
      console.log(e);
    }
    handleClose();
    setRequestedApproval(false);
  };

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove(STAKING_TOKEN_CONTRACT);
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      console.error(e);
    }
  }, [onApprove]);

  function handleInputChange(event) {
    let number = parseInt(event.target.value);
    if (isNaN(number)) number = 0;
    setAmount(number);
  }

  function maxQuantity() {
    setAmount(new BigNumber(balance).div(1e18).toNumber());
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay
        background="rgba(24, 31, 81, 0.8)"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader
          fontWeight={700}
          fontSize="24px"
          lineHeight="33px"
          borderBottom="1px solid #EEEEEE"
          display="flex"
          alignItems="center"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="#FFB524" top="16px" />
        <ModalBody borderBottom="1px solid #EEEEEE">
          <div style={{ minHeight: "30px" }}>
            <Flex w="100%" justifyContent="space-between" marginBottom="10px">
              <div>Amount</div>
              <div>Balance: {getFullDisplayBalance(balance, 18, 2)} CLOUD</div>
            </Flex>
            <Flex w="100%" justifyContent="space-between" marginBottom="10px">
              <Input
                type="number"
                value={amount}
                onChange={handleInputChange}
                disabled={requestedApproval}
              />
              <Button
                marginLeft="10px"
                onClick={maxQuantity}
                isDisabled={requestedApproval}
              >
                Max
              </Button>
            </Flex>
            <Flex w="100%" justifyContent="space-between" marginBottom="10px">
              <div>APR</div>
              <div>{pools[id].apr}%</div>
            </Flex>
            <Flex w="100%" justifyContent="space-between" marginBottom="10px">
              <div>Locking time</div>
              <div>{pools[id].lockingTime} months</div>
            </Flex>
          </div>
        </ModalBody>

        <ModalFooter>
          {!requestedApproval ? (
            allowance.div(1e18).toNumber() >= amount ? (
              <Button
                background="#FFB524 !important"
                w="100%"
                borderRadius="8px"
                onClick={handleStake}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
                isDisabled={amount <= 0}
              >
                Stake
              </Button>
            ) : (
              <Button
                background="#FFB524 !important"
                w="100%"
                borderRadius="8px"
                onClick={handleApprove}
                fontWeight={700}
                fontSize={16}
                line-height="140%"
              >
                Approve
              </Button>
            )
          ) : (
            <Button
              background="#EDF2F7 !important"
              w="100%"
              borderRadius="8px"
              fontWeight={700}
              fontSize={16}
              line-height="140%"
              cursor="default"
            >
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CustomModal;

/* eslint-disable import/prefer-default-export */
export const colors = {
  global: {
    menuText: "rgba(24, 31, 81, 0.49)",
    dividerColor: "#181F51",
    yellowOrange: "#FFB524",
    greenText: "#06AE86",
    grey: "rgba(24, 31, 81, 0.05)",
    secondYellow: "rgba(255, 181, 36, 0.2)",
    greyText: "rgba(24, 31, 81, 0.4)",
    neonGreenText: "#89ED8D",
    neonYellowText: "#FFCD5A",
    pink: "#FF4E63",
  },
  progress: {
    500: "#FFB524",
  },
  specialRed: {
    100: "#FF3A44",
  },
  green500: {
    100: "#136C40",
  },
  blue500: {
    100: "#003E78",
  },
  deepBlue500: {
    100: "#181F51",
    800: "#89ED8D",
  },
};

const noMargin = {
  marginBlockStart: "0px",
  marginBlockEnd: "0px",
  marginInlineStart: "0px",
  marginInlineEnd: "0px",
};

export const customGlobal = {
  styles: {
    global: {
      p: {
        ...noMargin,
      },
      div: {
        ...noMargin,
      },
      a: {
        ...noMargin,
      },
      span: {
        ...noMargin,
      },
    },
  },
};

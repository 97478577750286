import React from "react";
import { VStack } from "@chakra-ui/react";
import Item from "./Item";

export default function ListItems() {
  return (
    <VStack w="100%">
      <Item name="common" />
      <Item name="rare" />
      <Item name="legendary" />
    </VStack>
  );
}

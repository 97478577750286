import { Box, Grid, GridItem } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { TransparentHeader } from "src/components/common/Header";
import { isMobileScreen } from "src/utils/responsive";
import Content from "./components/Content";
import Sidebar from "./components/Sidebar";
import { useMyItems } from "src/hooks/useMyItems";

function MyItems() {
  const [isFixedFilter, setIsFixedFilter] = useState(false);
  const [nftStatusFilter, setNftStatusFilter] = useState("All");
  const [nftRankFilter, setNftRankFilter] = useState("All");
  const [nftCollectionFilter, setNftCollectionFilter] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = isMobileScreen();
  const nftItems = useMyItems(nftStatusFilter, setIsLoading, 1, 0);
  return (
    <Grid
      templateAreas={{
        xl: `"header header"
        "nav main"
        "nav main"`,
        base: `"header"
        "nav"
        "main"`,
      }}
      gridTemplateRows={{
        xl: "72px 1fr 30px",
        base:
          isFixedFilter && isMobile
            ? "48px 61px calc(100% - (48px + 61px))"
            : "48px 120px calc(100% - (48px + 120px))",
      }}
      gridTemplateColumns={{
        xl: "260px 1fr",
        base: "100%",
      }}
      width="100%"
      height="100%"
    >
      <GridItem area="header" width="100%">
        <TransparentHeader
          containerStyle={{
            height: {
              xl: "72px",
              base: "48px",
            },
            width: "100%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
            padding: {
              xl: "11px 30px",
              base: "12px 24px",
            },
            zIndex: "9999",
          }}
        />
      </GridItem>
      <GridItem area="nav" overflow="auto">
        <Box
          padding={{
            xl: "20px 30px",
            base: "12px 24px",
          }}
          height={{
            xl: "unset",
            base: "100%",
          }}
        >
          <Sidebar
            isFixedFilter={isFixedFilter}
            setNftStatusFilter={setNftStatusFilter}
            setNftRankFilter={setNftRankFilter}
            setNftCollectionFilter={setNftCollectionFilter}
          />
        </Box>
      </GridItem>
      <GridItem area="main">
        <Content
          setIsFixedFilter={setIsFixedFilter}
          isFixedFilter={isFixedFilter}
          isLoading={isLoading}
          nftItems={nftItems
            .filter(
              (item) => item.rank === nftRankFilter || nftRankFilter === "All"
            )
            .filter(
              (item) =>
                item.collection === nftCollectionFilter ||
                nftCollectionFilter === "All"
            )}
        />
      </GridItem>
    </Grid>
  );
}

export default MyItems;

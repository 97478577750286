import React from "react";
import {
  HStack,
  Icon,
  Text,
  Collapse,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";

const ITEM_MAPS: any = {
  common: {
    color: "#FFFFFF",
    background:
      "linear-gradient(269.8deg, rgba(195, 192, 255, 0) 0.16%, rgba(147, 143, 242, 0.4) 49.47%)",
  },
  rare: {
    color: "#01B976",
    background:
      "linear-gradient(269.8deg, rgba(1, 185, 118, 0) 52.59%, rgba(1, 185, 118, 0.4) 99.82%)",
  },
  legendary: {
    color: "#FFB524",
    background:
      "linear-gradient(269.8deg, rgba(255, 181, 36, 0) 78.02%, rgba(255, 181, 36, 0.4) 99.82%)",
  },
};

function RectangleIcon(props: any) {
  const { fill } = props;

  return (
    <Icon marginTop="8px">
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6 0.5L12 6.5L6 12.5L0 6.5L6 0.5Z" fill={fill} />
      </svg>
    </Icon>
  );
}

function StarIcon(props: any) {
  const { isFilled } = props;
  return (
    <Icon>
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0.5L14.6942 8.7918H23.4127L16.3593 13.9164L19.0534 22.2082L12 17.0836L4.94658 22.2082L7.64074 13.9164L0.587322 8.7918H9.30583L12 0.5Z"
          fill={isFilled ? "#FFB524" : "white"}
          fillOpacity={isFilled ? 1 : 0.2}
        />
      </svg>
    </Icon>
  );
}

export default function Item(props: any) {
  const { name } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex
      direction="column"
      padding="30px"
      w="100%"
      opacity={isOpen ? "1" : "0.6"}
      background={ITEM_MAPS[name].background}
      onClick={onToggle}
      cursor="pointer"
      // onMouseEnter={onToggle}
      // onMouseLeave={onToggle}
    >
      <HStack w="100%" gap="20px">
        <RectangleIcon fill={ITEM_MAPS[name].color} />
        <Text
          textTransform="capitalize"
          color={ITEM_MAPS[name].color}
          fontSize={32}
          fontWeight={600}
          fontFamily="Clash Display, sans-serif"
        >
          {name}
        </Text>
        <HStack>
          <StarIcon isFilled={["common", "rare", "legendary"].includes(name)} />
          <StarIcon isFilled={["rare", "legendary"].includes(name)} />
          <StarIcon isFilled={["legendary"].includes(name)} />
        </HStack>
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Text
          color="white"
          textTransform="capitalize"
          fontSize={14}
          fontWeight={600}
          marginTop="24px"
          fontFamily="Poppins, sans-serif"
        >
          Infomation:
        </Text>
        <Text
          textTransform="capitalize"
          color={ITEM_MAPS[name].color}
          fontSize={14}
          fontWeight={400}
          marginTop="10px"
          marginBottom="24px"
          fontFamily="Poppins, sans-serif"
        >
          {name === "common" && (
            <>
              The ordinary residents of the UpSky Kingdom, with their endearing
              accessories and artifacts from their adventures
            </>
          )}
          {name === "rare" && (
            <>
              Civilized Upsky Kingdom residents who have seen more of the world
              than most and always have many exotic artifact and relic with
              them.
            </>
          )}
          {name === "legendary" && (
            <>
              The most elite residents of UpSky Kingdom - they are knowledgeable
              adventurers that always at the forefront of new adventures and
              always bringing with them their finest armor and possessions
            </>
          )}
        </Text>
        {/* <Text
          color="white"
          textTransform="capitalize"
          fontSize={14}
          fontWeight={600}
          fontFamily="Poppins, sans-serif"
        >
          Discount: 15%
        </Text> */}
      </Collapse>
    </Flex>
  );
}

import { FC } from "react";
import { ReactComponent } from "../../assets/icons/close.svg";
import { ReactNode } from "react";
import styles from "./style.module.css";

interface ModalProps {
  children?: ReactNode;
  onClick?: () => any;
  onClose?: () => any;
  border?: boolean;
  className?: string;

  open: boolean;

  custom?: any;

  showClose?: boolean;
}

const Modal: FC<ModalProps> = (props) => {
  const {
    onClick,
    onClose,
    children,
    custom,
    className,
    showClose = false,
    open = false,
    border,
  } = props;
  const styleProps = { border: border, ...custom };

  const handleClose = () => {
    if (onClick) onClick();
    if (onClose) onClose();
  };

  return (
    <>
      <div
        onClick={handleClose}
        className={[styles.modal, `${open ? "" : "d-none"}`].join(" ")}
      />
      <div
        style={styleProps}
        className={[
          styles.modalStyle,
          className,
          `${open ? "" : "d-none"}`,
        ].join(" ")}
      >
        <div className={styles.modalGradient}>
          {showClose && (
            <button className={styles.modalButton} onClick={handleClose}>
              <ReactComponent />
            </button>
          )}
          <div className={styles.modalContainer}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;

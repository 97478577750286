import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { buyNFT } from "../utils/callHelpers";
import { getBuyNFTWithUSDTContract } from "src/utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";

export const useBuyNFTWithUSDT = () => {
  const { account } = useWeb3React();

  const web3 = useWeb3();
  const contract = getBuyNFTWithUSDTContract(web3);

  const handleBuy = useCallback(
    async (nftType, quantity) => {
      const txHash = await buyNFT(contract, nftType, quantity, account);
      return txHash;
    },
    [account, contract]
  );

  return { onBuyNFT: handleBuy };
};

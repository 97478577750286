import { Box, Stack, Text } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { TransparentHeader } from "src/components/common/Header";
import mainBackgroundImage from "src/assets/skybg.png";
import Referral from "src/pages/teams/components/Referral";
import Info from "src/pages/teams/components/Info";
import { useTeam } from "../../state/hooks";
import Members from "./components/Members";
import { useNftBalance } from "src/hooks/useNftBalance";

function TeamsPage() {
  const { id: idStr } = useParams();
  const { teams, isInitialized, isLoading } = useTeam(idStr);
  const [nftBalance, nftStaking, nftValue] = useNftBalance(idStr);
  const commissionEarned = teams.totalCommission;
  const teamInvestment = teams.points;

  if (!isInitialized) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          top: "calc(50% - 16px)",
          position: "fixed",
          left: "0",
          right: "0",
        }}
      >
        <div className="spinner-border text-light" role="status"></div>
      </div>
    );
  }

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
      overflowX="hidden"
    >
      <TransparentHeader isFixed />
      <Stack
        height="100%"
        direction="column"
        spacing={[8, 22]}
        paddingTop={["80px", "80px", "120px"]}
        px="30px"
      >
        <Text
          fontFamily="Clash Display, sans-serif"
          // color="white"
          fontSize="40px"
          fontWeight={600}
          lineHeight="49px"
          mb={["20px"]}
          align={["center", "center", "left"]}
        >
          Teams
        </Text>
        <div>
          <Link to="/my-network">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                viewBox="0 0 24 24"
                color="primary"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  alignSelf: "center",
                  flexShrink: 0,
                }}
              >
                <path d="M14.71 15.88L10.83 12L14.71 8.12001C15.1 7.73001 15.1 7.10001 14.71 6.71001C14.32 6.32001 13.69 6.32001 13.3 6.71001L8.70998 11.3C8.31998 11.69 8.31998 12.32 8.70998 12.71L13.3 17.3C13.69 17.69 14.32 17.69 14.71 17.3C15.09 16.91 15.1 16.27 14.71 15.88Z"></path>
              </svg>
              <div>Teams Overview</div>
            </div>
          </Link>
        </div>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border text-light" role="status"></div>
          </div>
        ) : (
          <>
            <Info
              activeMembers={teams.users}
              nftBalance={nftBalance}
              nftStaking={nftStaking}
              nftValue={nftValue}
              commissionEarned={commissionEarned}
              teamInvestment={teamInvestment}
            />
            <Members
              referralAddresses={teams.referralAddresses}
              referralList={teams.referralList}
              users={teams.users}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}

export default TeamsPage;

import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import backgroundImage from "src/assets/bg.png";
import { isMobileScreen } from "src/utils/responsive";
import debounce from "lodash.debounce";
import ImageDetailsModal from "./ImageDetailsModal";
import NFTDetailsModal from "./NFTDetailsModal";
import SellModal from "./SellModal";
import NFTItems from "./NFTItems";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "src/hooks/useAuth";
import { useWalletModal } from "src/components/common/WalletModal";

interface IContent {
  // eslint-disable-next-line no-unused-vars
  setIsFixedFilter: (x: boolean) => void;
  isFixedFilter: boolean;
  isLoading: boolean;
  nftItems: any;
}

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

function Content({
  setIsFixedFilter,
  isFixedFilter,
  isLoading,
  nftItems,
}: IContent) {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedItemForSell, setSelectedItemForSell] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedStep, setSelectedStep] = useState<any>(null);
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  const isMobile = isMobileScreen();

  const handleContentScroll = (e: any): void => {
    if (isMobile) {
      const currentOffsetTop = e.target?.scrollTop;
      if (currentOffsetTop > 0 && !isFixedFilter) {
        setIsFixedFilter(true);
      } else if (currentOffsetTop === 0 && isFixedFilter) {
        setIsFixedFilter(false);
      }
    }
  };

  const debounceHandleScroll = debounce(handleContentScroll, 200);

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        height="100%"
        width="100%"
        overflow="auto"
        zIndex="10"
        padding={{
          xl: "43px 87px",
          base: "27px 20px",
        }}
        position="relative"
        onScroll={debounceHandleScroll}
      >
        {!account ? (
          <div
            style={{
              textAlign: "center",
              width: "100%",
              color: "#fff",
              fontSize: "20px",
              marginTop: "50px",
            }}
          >
            <div>Please unlock your wallet!</div>
            <div style={{ marginTop: "10px" }}>
              <Button
                {...activeButtonStyle}
                style={{ width: "auto" }}
                onClick={onPresentConnectModal}
              >
                Connect Wallet
              </Button>
            </div>
          </div>
        ) : isLoading ? (
          <div style={{ textAlign: "center" }}>
            <div className="spinner-border text-light" role="status"></div>
          </div>
        ) : (
          <Flex
            flexDirection={{
              xl: "row",
              base: "column",
            }}
            align={{
              xl: "stretch",
              base: "center",
            }}
            justify="start"
            zIndex="999"
            marginBottom={{
              xl: "0px",
              base: "12px",
            }}
            flexWrap="wrap"
          >
            {nftItems &&
              nftItems.map((item, index) => {
                return (
                  <NFTItems
                    items={item}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                    onClickImage={() => {
                      setSelectedImage(item);
                    }}
                    onClickSellButton={() => {
                      setSelectedItemForSell(item);
                    }}
                    setSelectedStep={setSelectedStep}
                  />
                );
              })}

            {nftItems.length === 0 && (
              <>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "#fff",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                >
                  <div>No NFTs found!</div>
                  <div style={{ marginTop: "10px" }}>
                    <Link to="/">
                      <Button {...activeButtonStyle}>Buy NFT</Button>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </Flex>
        )}
      </Box>
      <Box
        style={{
          backgroundImage: `url("${backgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "100%",
          width: "100%",
          opacity: "0.8",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
      {selectedItem && (
        <NFTDetailsModal
          isOpen={!!selectedItem}
          onClose={() => {
            setSelectedItem(null);
            setSelectedStep(null);
          }}
          selectedStep={selectedStep}
          item={selectedItem}
        />
      )}
      {selectedImage && (
        <ImageDetailsModal
          isOpen={!!selectedImage}
          onClose={() => {
            setSelectedImage(null);
          }}
          item={selectedImage}
        />
      )}
      {selectedItemForSell && (
        <SellModal
          isOpen={!!selectedItemForSell}
          onClose={() => {
            setSelectedItemForSell(null);
          }}
          item={selectedItemForSell}
        />
      )}
    </Box>
  );
}
export default Content;

import React from "react";
import { VStack, HStack, Text, Image, Stack } from "@chakra-ui/react";
import POOL1 from "src/assets/BGN.png";
import POOL2 from "src/assets/BGV.png";
import BOX from "src/assets/Box1.png";
import { Link } from "react-router-dom";

const TAG_MAPS: any = {
  ordinary: {
    name: "The Ordinary",
    title: "Normal Pool",
    color: "#E4E4E4",
    background:
      "linear-gradient(91.61deg, #C8C8C8 0%, #FDFDFD 18.75%, #C2C2C2 86.46%, #FFFFFF 100%)",
    border:
      "linear-gradient(91.61deg, #FFFFFF 0%, #FFFFFF 0.52%, #C2C2C2 15.62%, #FDFDFD 78.65%, #CCCCCC 100%)",
    APY: "72%",
    dailyAPY: "0.197%",
  },
  supremacy: {
    name: "The Supremacy",
    title: "V.I.P Pool",
    color: "#FFDB6A",
    background:
      "linear-gradient(90.8deg, #FFEA97 0%, #FFDB6A 59.3%, #FFCE4A 76.46%, #FFC133 87.38%, #FFA800 99.87%, #FFB320 99.87%)",
    border:
      "linear-gradient(90.89deg, #FFA800 0%, #FFC133 13.26%, #FFCE4A 26.2%, #FFDB6A 38.82%, #FFEA97 100%)",
    APY: "96%",
    dailyAPY: "0.263%",
  },
};

export default function Pool(props: any) {
  const { type, name } = props;
  return (
    <HStack
      width="100%"
      minHeight={["150px", "416px", "416px"]}
      height="auto"
      flexBasis="calc(50% - 1rem)"
      backgroundImage={`url(${type === 1 ? POOL1 : POOL2});`}
      backgroundSize="cover"
      backgroundPosition="center"
      borderRadius="20px"
      position="relative"
      padding="12px"
      display="flex"
    >
      <Link to={`/staking?pool=${type === 1 ? "ordinary" : "supremacy"}`}>
        <Image
          position="absolute"
          left="50%"
          top="30%"
          transform="translate(-50%, -50%)"
          src={BOX}
          borderTopRadius="8"
          transition="transform .5s ease"
          _hover={{ transform: "translate(-50%, -50%) scale(1.5)" }}
          display={["none", "none", "block"]}
        />
        <Image
          src={BOX}
          borderTopRadius="8"
          width="130px"
          height="130px"
          display={["block", "none", "none"]}
        />
      </Link>
      <Stack
        direction={["column", "column", "row"]}
        width="100%"
        borderRadius="10px"
        background={TAG_MAPS[name].background}
        padding="20px"
        alignSelf="flex-end"
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start">
          <Text
            lineHeight={["17px", "17px", "37px"]}
            fontSize={["14px", "14px", "30px"]}
            fontWeight={600}
            whiteSpace="nowrap"
            fontFamily="Clash Display, sans-serif"
            color="black"
          >
            {TAG_MAPS[name].name}
          </Text>
          <Text
            fontFamily="Poppins, sans-serif"
            mt="0px !important"
            fontSize={["12px", "16px", "16px"]}
            fontWeight={600}
            color="black"
          >
            {TAG_MAPS[name].title}
          </Text>
        </VStack>
        <VStack
          width={["100%", "40%", "40%"]}
          background="linear-gradient(91.61deg, rgba(200, 200, 200, 0.2) 0%, rgba(253, 253, 253, 0.2) 18.75%, rgba(194, 194, 194, 0.2) 86.46%, rgba(255, 255, 255, 0.2) 100%), #FFFFFF;
          box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.08);"
          //   background={TAG_MAPS[name].background}
          boxShadow="inset 0px 4px 10px rgba(0, 0, 0, 0.08)"
          borderRadius="10px"
          padding="12px"
        >
          <HStack width="full" justifyContent="space-between">
            <Text
              lineHeight={["18px", "18px", "18px"]}
              fontSize={["12px", "12px", "12px"]}
              fontWeight={500}
              whiteSpace="nowrap"
              color="black"
            >
              APY
            </Text>
            <Text
              lineHeight={["18px", "18px", "18px"]}
              fontSize={["12px", "12px", "12px"]}
              fontWeight={600}
              color="black"
            >
              {TAG_MAPS[name].APY}
            </Text>
          </HStack>
          <HStack width="full" justifyContent="space-between">
            <Text
              lineHeight={["18px", "18px", "18px"]}
              fontSize={["12px", "12px", "12px"]}
              fontWeight={500}
              whiteSpace="nowrap"
              color="black"
            >
              Daily APY
            </Text>
            <Text
              lineHeight={["18px", "18px", "18px"]}
              fontSize={["12px", "12px", "12px"]}
              fontWeight={600}
              color="black"
            >
              {TAG_MAPS[name].dailyAPY}
            </Text>
          </HStack>
        </VStack>
      </Stack>
    </HStack>
  );
}

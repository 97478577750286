import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { recordReferral } from "../utils/callHelpers";
import useReferrer from "./useReferer";
import { getRecordReferralContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import { fetchTeams } from "../state/teams";
import { useAppDispatch } from "../state";

const useRecordReferral = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const web3 = useWeb3();
  const recordReferralContract = getRecordReferralContract(web3);
  const { ref } = useReferrer();
  const handleJoin = useCallback(async () => {
    const txHash = await recordReferral(account, recordReferralContract, ref);
    dispatch(fetchTeams(account));
    return txHash;
  }, [account, recordReferralContract, ref]);

  return { onJoin: handleJoin };
};
export default useRecordReferral;

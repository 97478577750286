export const DEFAULT_GAS_PRICE = 5;
export const TESTNET_CHAIN_ID = "97";
export const MAINNET_CHAIN_ID = "56";

export const SALE_NFT_CONTRACT = "0xEFADc4157800189857B90dC956f9d1aa73F70b54";
export const SALE_NFT_USDT_CONTRACT =
  "0x0E46103549aE23Db0d4C328B7230BF879E4da41b";

export const RECORD_REFERRAL_CONTRACT =
  "0xCA99ffe78Da70d3fb0bF37343562662865094647";
export const REFERRAL_CONTRACT = "0x6BB6324B2CF6AcBE70CE8c7E4f8B489C18af5542";
export const NFT_CONTRACT = "0x4a4ed2069ace9d0835a03716174c3c8f8882dc93";
export const MULTICALL_CONTRACT = "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B";
export const STAKING_NFT_CONTRACT =
  "0x84F1A173a98d120A4a0f99c7F41e5baAdB13106a";

export const USDT_TOKEN = "0x55d398326f99059ff775485246999027b3197955";
export const CLOUD_TOKEN = "0x5C10E4660eC45a89dE85a1FD1B22355B0398fB66";

export const SELL_NFT_CONTRACT = "0x824DfD3A823f5c28F596eAE44670760D0eDE4477";

export const SALE_OFF_NFT_CONTRACT = "";

export const STAKING_TOKEN_CONTRACT =
  "0x74b93AE9122E9894A7fCf43ECb3572a76E3cBe37";

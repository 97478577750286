import React from "react";
import { VStack, Stack, HStack, Text, Image } from "@chakra-ui/react";

import { useFetchPoolInfo } from "src/hooks/useFetchPoolInfo";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import NFTImage1 from "src/assets/NFT1.png";
import NFTImage2 from "src/assets/NFT2.png";
import NFTImage3 from "src/assets/NFT3.png";
import { isMobileScreen } from "src/utils/responsive";

export default function PageHeader() {
  const poolInfo = useFetchPoolInfo();

  return (
    <VStack width="fit-content">
      <VStack justifyContent="center" alignItems="center">
        <Text
          fontFamily="Clash Display, sans-serif"
          fontWeight={600}
          fontSize="42px"
          lineHeight="52px"
          color="#FFB524"
        >
          Choose a Pool
        </Text>
        <Text
          fontFamily="Clash Display, sans-serif"
          fontWeight={600}
          fontSize="20px"
          lineHeight="25px"
        >
          Stake Cloud to get profit
        </Text>
      </VStack>
      <Stack direction="column" gap="20px" marginTop="40px !important">
        <HStack
          background="linear-gradient(0deg, rgba(147, 143, 242, 0.4), rgba(147, 143, 242, 0.4)), #181F51;"
          borderRadius="20px"
          padding="16px 55px 16px 20px"
        >
          <Image w="60px" h="60px" src={NFTImage1} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total NFT locked
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              {poolInfo.totalNftLocked} CLOUD
            </Text>
          </VStack>
        </HStack>
        <HStack
          background="linear-gradient(0deg, rgba(147, 143, 242, 0.4), rgba(147, 143, 242, 0.4)), #181F51;"
          borderRadius="20px"
          padding="16px 55px 16px 20px"
        >
          <Image w="60px" h="60px" src={NFTImage2} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total value locked
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              ${getFullDisplayBalance(poolInfo.totalValueLocked, 18, 0)}
            </Text>
          </VStack>
        </HStack>
        <HStack
          background="linear-gradient(0deg, rgba(147, 143, 242, 0.4), rgba(147, 143, 242, 0.4)), #181F51;"
          borderRadius="20px"
          padding="16px 55px 16px 20px"
        >
          <Image w="60px" h="60px" src={NFTImage3} />
          <VStack alignItems="flex-start">
            <Text
              fontFamily="Clash Display, sans-serif"
              fontWeight={600}
              fontSize="20px"
              lineHeight="25px"
            >
              Total earned
            </Text>
            <Text
              fontFamily="Poppins, sans-serif"
              fontWeight={700}
              fontSize="26px"
              lineHeight="39px"
              color="#FFB524"
              mt="0 !important"
            >
              {getFullDisplayBalance(poolInfo.totalEarn, 18, 0)} CLOUD
            </Text>
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  );
}

import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getStakingContract, getNFTContract } from "../utils/contractHelpers";
import useWeb3 from "../hooks/useWeb3";
import BigNumber from "bignumber.js";
import useRefresh from "./useRefresh";

export const useFetchStakingInfo = (
  activeLeftTab
): { totalNftLocked: any; totalValueLocked: any; totalEarn: any } => {
  const [totalNftLocked, setTotalNftLocked] = useState(0);
  const [totalValueLocked, setTotalValueLocked] = useState(new BigNumber(0));
  const [totalEarn, setTotalEarn] = useState(new BigNumber(0));
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);
  const nftContract = getNFTContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchStakingInfo = async () => {
      const lockedValue = await contract.methods.totalStake(account).call();
      setTotalValueLocked(new BigNumber(lockedValue));

      const pending = await contract.methods
        .pendingRewardByUser(account)
        .call();
      setTotalEarn(new BigNumber(pending));

      const nftStaking = await nftContract.methods
        .countTotalLockedToken(account)
        .call();
      setTotalNftLocked(nftStaking);
    };
    if (account) {
      fetchStakingInfo();
    }
  }, [account, web3, fastRefresh, activeLeftTab]);

  return {
    totalNftLocked,
    totalValueLocked,
    totalEarn,
  };
};

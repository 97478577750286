import { Box, Button, Stack, Text, Container } from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";

import { useTeam } from "../../state/hooks";
import { TransparentHeader } from "src/components/common/Header";
import mainBackgroundImage from "src/assets/skybg.png";
import Referral from "src/pages/teams/components/Referral";
import Info from "src/pages/teams/components/Info";
import Members from "./components/Members";
import { useNftBalance } from "src/hooks/useNftBalance";

function ReferralPage() {
  const { account } = useWeb3React();
  const { teams, isLoading } = useTeam(account);
  const [nftBalance, nftStaking, nftValue] = useNftBalance(account);
  const commissionEarned = teams.totalCommission;
  const teamInvestment = teams.points;

  return (
    <Box
      height="100%"
      width="auto"
      style={{
        backgroundImage: `url("${mainBackgroundImage}"), linear-gradient(180deg, #181F51 0%, #111745 20.31%, #0C103A 43.23%, #070A2E 66.67%, #040726 100%)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
      overflowX="hidden"
    >
      <TransparentHeader isFixed />
      <Stack
        height="100%"
        direction="column"
        spacing={[8, 22]}
        paddingTop={["80px", "80px", "150px"]}
        px="30px"
      >
        <Referral
          showUnlockButton={!(account && teams !== null)}
          owner={teams.owner}
        />

        {account &&
          (isLoading ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border text-light" role="status"></div>
            </div>
          ) : (
            <>
              <Info
                activeMembers={teams.users}
                nftBalance={nftBalance}
                nftStaking={nftStaking}
                nftValue={nftValue}
                commissionEarned={commissionEarned}
                teamInvestment={teamInvestment}
              />
              <Members
                referralAddresses={teams.referralAddresses}
                referralList={teams.referralList}
                users={teams.users}
              />
            </>
          ))}
      </Stack>
    </Box>
  );
}

export default ReferralPage;

import { createBrowserRouter } from "react-router-dom";
import NftSaleLayout from "./components/common/Layout/NftSaleLayout";
import MyItems from "./pages/myItems";
// import SaleLayout from "./components/common/Layout/SaleLayout";
// import SeedRound from "./pages/presale/SeedRound";
import SalePage from "./pages/sale";
import StakePage from "./pages/stake";
// import SaleBox from "./pages/saleBox";
import Team from "./pages/teams";
import Teams from "./pages/teams/teams";
import Pool from "./pages/pool";
import StakingToken from "./pages/staking-token";
import CloudAI from "./pages/cloudAi";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      // {
      //   path: "nft-sale-off",
      //   element: <NftSaleLayout />,
      //   children: [
      //     {
      //       path: "/nft-sale-off",
      //       element: <SeedRound />,
      //     },
      //   ],
      // },
      {
        path: "/",
        element: <SalePage />,
      },
      // {
      //   path: "sale-box",
      //   element: <SaleBox />,
      // },
      {
        path: "staking",
        element: <StakePage />,
      },
      {
        path: "my-items",
        element: <MyItems />,
      },
      {
        path: "my-network",
        element: <Team />,
      },
      {
        path: "teams/:id",
        element: <Teams />,
      },
      {
        path: "pool",
        element: <Pool />,
      },
      {
        path: "staking-token",
        element: <StakingToken />,
      },
      {
        path: "cloud-ai",
        element: <CloudAI />,
      },
    ],
  },
]);

export default router;

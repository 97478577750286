import { FC } from "react";

import "./dropdownitem.scss";

interface Props {
  label: string;
  color: string;
}

const DropDownItem: FC<Props> = ({ label, color }) => (
  <div className="drop-down-item">
    <div style={{ color }} className="drop-down-item-label">
      {label}
    </div>
    <div className="text-creative-level">Creative Level</div>
  </div>
);

export default DropDownItem;

/* eslint-disable react/no-children-prop */
import React, { useEffect } from "react";
import { Box, Button, Text, HStack, VStack } from "@chakra-ui/react";

import { useHarvestAll } from "src/hooks/useHarvestAll";
import { useFetchStakingInfo } from "src/hooks/useFetchStakingInfo";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { useRewardTokenPrice } from "src/hooks/useRewardTokenPrice";
import { useLocation } from "react-router-dom";

import StakeTab from "./StakeTab";
import ClaimTab from "./ClaimTab";

const activeButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#FFB524",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#181F51",
  _hover: {
    background: "#FFB524",
  },
};

const activeButton1Style = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  background: "#181F51",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "#FFB524",
  _hover: {
    background: "#181F51",
  },
};

const inactiveButtonStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  borderRadius: "8px",
  height: "38px",
  width: "120px",
  color: "rgba(24, 31, 81, 0.4)",
  background: "unset",
};

export default function MainTab(props: any) {
  const {
    setHideCalculator,
    selectedItems,
    setSelectedItems,
    setItems,
    setStakingPool,
    getNftTime,
  } = props;
  const [activeLeftTab, setActiveLeftTab] = React.useState(1);
  const [activeRightTab, setActiveRightTab] = React.useState(2);
  const [claimAllInProcess, setClaimAllInProcess] = React.useState(false);
  const { onHarvestAll } = useHarvestAll();
  const stakingInfo = useFetchStakingInfo(activeLeftTab);
  const cloudTokenPrice = useRewardTokenPrice();

  const { search } = useLocation();
  const tab = new URLSearchParams(search).get("tab");

  useEffect(() => {
    if (tab === "stake") {
      setActiveLeftTab(1);
    } else if (tab === "claim") {
      setActiveLeftTab(2);
    }
  }, [tab]);

  const handleSwitchLeftTab = (tab: number) => {
    setSelectedItems([]);
    setActiveLeftTab(tab);
  };

  const handleSwitchRightTab = (tab: number) => {
    setActiveRightTab(tab);
  };

  const getLeftTabStatus = (tab: number) => {
    return activeLeftTab === tab
      ? activeButtonStyle
      : {
          ...inactiveButtonStyle,
          _hover: {
            background: "#FFB524",
            color: "#181F51",
          },
        };
  };

  const getRightTabStatus = (tab: number) => {
    return activeRightTab === tab
      ? activeButton1Style
      : {
          ...inactiveButtonStyle,
          _hover: {
            background: "#181F51",
            color: "#FFB524",
          },
        };
  };

  useEffect(() => {
    if (activeLeftTab === 1) {
      setHideCalculator(true);
    } else {
      setHideCalculator(false);
    }
  }, [activeLeftTab, setHideCalculator]);

  useEffect(() => {
    setSelectedItems([]);
    setStakingPool(activeRightTab === 2 ? "Ordinary" : "Supremacy");
  }, [activeRightTab]);

  const _stakingPool = new URLSearchParams(search).get("pool");
  useEffect(() => {
    if (_stakingPool === "ordinary") {
      setActiveRightTab(2);
    } else if (_stakingPool === "supremacy") {
      setActiveRightTab(3);
    }
  }, [_stakingPool]);

  return (
    <>
      <Box
        width="fit-content"
        // mt="100px"
        borderRadius="8px"
        background="rgba(255, 181, 36, 0.4)"
        display={["flex", "flex", "none"]}
      >
        <Button
          onClick={() => handleSwitchLeftTab(1)}
          m="0 !important"
          {...getLeftTabStatus(1)}
        >
          Stake
        </Button>
        <Button
          onClick={() => handleSwitchLeftTab(2)}
          m="0 !important"
          {...getLeftTabStatus(2)}
        >
          Claiming
        </Button>
      </Box>
      <Box
        height="auto"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.08)"
        borderRadius="20px"
        w={["100%", "100%", "100%"]}
        alignSelf="flex-start"
        order={["2", "1", "1"]}
      >
        <Box
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          padding="20px 30px"
          background="#181F51"
          w="full"
          borderTopRadius="20px"
          display={["flex", "flex", "none"]}
        >
          <Text
            color="#FFB524"
            fontFamily="Clash Display, sans-serif"
            fontWeight={700}
            fontSize="24px"
            lineHeight="140%"
            alignSelf="flex-start"
          >
            {activeLeftTab === 1 ? "Choose your Assets" : "Claiming"}
          </Text>

          {activeLeftTab === 2 && (
            <>
              <HStack
                w="full"
                justifyContent="space-between"
                display={["flex", "flex", "none"]}
              >
                <Text
                  fontFamily="Poppins, sans-serif"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color=" rgba(255, 255, 255, 0.4);"
                >
                  Cloud Token
                </Text>
                <Text
                  color="#FFFFFF"
                  fontFamily="Poppins, sans-serif"
                  fontWeight={600}
                  fontSize="14px"
                  lineHeight="24px"
                  align="left"
                >
                  $0.20
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                display={["flex", "flex", "none"]}
              >
                <Text
                  fontFamily="Poppins, sans-serif"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color=" rgba(255, 255, 255, 0.4);"
                >
                  NFT locked
                </Text>
                <Text
                  color="#FFFFFF"
                  fontFamily="Poppins, sans-serif"
                  fontWeight={600}
                  fontSize="14px"
                  lineHeight="24px"
                  align="left"
                >
                  {stakingInfo.totalNftLocked}
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                display={["flex", "flex", "none"]}
              >
                <Text
                  fontFamily="Poppins, sans-serif"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color=" rgba(255, 255, 255, 0.4);"
                >
                  Value locked
                </Text>
                <Text
                  color="#FFFFFF"
                  fontFamily="Poppins, sans-serif"
                  fontWeight={600}
                  fontSize="14px"
                  lineHeight="24px"
                  align="left"
                >
                  <>
                    {getFullDisplayBalance(stakingInfo.totalValueLocked, 18, 2)}{" "}
                    USDT
                  </>
                </Text>
              </HStack>
              <HStack
                w="full"
                justifyContent="space-between"
                display={["flex", "flex", "none"]}
              >
                <Text
                  fontFamily="Poppins, sans-serif"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                  color=" rgba(255, 255, 255, 0.4);"
                >
                  Earned
                </Text>
                <Text
                  color="#06AE86"
                  fontFamily="Poppins, sans-serif"
                  fontWeight={600}
                  fontSize="14px"
                  lineHeight="24px"
                  align="left"
                >
                  <>
                    {(
                      (stakingInfo.totalEarn * cloudTokenPrice) /
                      1e8 /
                      1e18
                    ).toFixed(2)}{" "}
                    USDT{" "}
                    <small>
                      (~ {getFullDisplayBalance(stakingInfo.totalEarn, 18, 2)}{" "}
                      CLOUD)
                    </small>
                  </>
                </Text>
              </HStack>
              <Button
                style={{ marginTop: "10px" }}
                {...activeButtonStyle}
                disabled={claimAllInProcess}
                onClick={async () => {
                  setClaimAllInProcess(true);
                  try {
                    await onHarvestAll();
                    setClaimAllInProcess(false);
                  } catch (e) {
                    setClaimAllInProcess(false);
                  }
                }}
              >
                {claimAllInProcess ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ color: "#181F51" }}
                  ></div>
                ) : (
                  <>Claim All</>
                )}
              </Button>
            </>
          )}
        </Box>
        <HStack
          padding="18px 30px"
          flexDirection="row"
          justifyContent="space-between"
          borderBottom="1px solid rgba(24, 31, 81, 0.5)"
          background={
            activeLeftTab === 1
              ? "linear-gradient(0deg, rgba(255, 181, 36, 0.2), rgba(255, 181, 36, 0.2)), #ffffff !important"
              : "#ffffff"
          }
          borderTopRadius={["0px", "20px", "20px"]}
          display={["none", "none", "flex"]}
        >
          <Box borderRadius="8px" background="rgba(255, 181, 36, 0.4)">
            <Button
              onClick={() => handleSwitchLeftTab(1)}
              m="0 !important"
              {...getLeftTabStatus(1)}
            >
              Stake
            </Button>
            <Button
              onClick={() => handleSwitchLeftTab(2)}
              m="0 !important"
              {...getLeftTabStatus(2)}
            >
              Claiming
            </Button>
          </Box>
          <HStack gap={0}>
            <Text
              color="#181F51"
              marginRight="24px"
              fontFamily="Poppins, sans-serif"
              fontWeight={600}
              fontSize="16px"
              lineHeight="140%"
              display={["none", "none", "block"]}
            >
              Staking Pool:
            </Text>
            <Box borderRadius="8px" background="rgba(255, 181, 36, 0.4)">
              <Button
                onClick={() => handleSwitchRightTab(2)}
                m="0 !important"
                {...getRightTabStatus(2)}
              >
                Ordinary
              </Button>
              <Button
                onClick={() => handleSwitchRightTab(3)}
                m="0 !important"
                {...getRightTabStatus(3)}
              >
                Supremacy
              </Button>
            </Box>
          </HStack>
        </HStack>
        <VStack
          background={
            activeLeftTab === 1
              ? "linear-gradient(0deg, rgba(255, 181, 36, 0.2), rgba(255, 181, 36, 0.2)), #ffffff !important"
              : "#ffffff"
          }
          padding={["12px", "30px", "30px"]}
          borderBottomRadius={["20px", "20px", "20px"]}
        >
          {activeLeftTab === 1 && (
            <StakeTab
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setItems={setItems}
              stakingPool={activeRightTab === 2 ? "Ordinary" : "Supremacy"}
              getNftTime={getNftTime}
              handleSwitchRightTab={handleSwitchRightTab}
              getRightTabStatus={getRightTabStatus}
            />
          )}
          {activeLeftTab === 2 && <ClaimTab stakingInfo={stakingInfo} />}
        </VStack>
      </Box>
    </>
  );
}

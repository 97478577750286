import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "./useRefresh";
import useWeb3 from "../hooks/useWeb3";
import { getStakingContract } from "src/utils/contractHelpers";

export const useRewardTokenPrice = () => {
  // return 20000000;

  const [rewardTokenPrice, setRewardTokenPrice] = useState(0);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getStakingContract(web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchPrice = async () => {
      const res = await contract.methods.rewardTokenPrice().call();
      setRewardTokenPrice(res);
    };
    if (account) {
      fetchPrice();
    }
  }, [account, contract, fastRefresh]);

  // console.log("rewardTokenPrice", rewardTokenPrice);

  return rewardTokenPrice;
};

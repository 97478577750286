import { Box, Button, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";

import LogoImage from "src/assets/newlogo.png";
import CartLogo from "src/assets/cart.png";
import CartLogoDark from "src/assets/cart_dark.png";
// import MailUnread from "src/assets/MailUnread.png";
// import MailUnreadDark from "src/assets/MailUnread_dark.png";
import HotSale from "src/assets/hotsale.png";
import wallet from "src/assets/wallet.png";
import menu from "src/assets/menu.png";
import menuDark from "src/assets/menuDark.png";
import OrangeButton from "../Button/OrangeButton";
// import NotificationModal from "../Notification";
import MobileMenuModal from "../MobileMenuModal";
import { useWalletModal, Login } from "../WalletModal";
import useCountdown from "../../../hooks/useCountdown";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { isMobileScreen } from "src/utils/responsive";

const buttonMenuStyle = {
  variant: "unstyled",
  textDecoration: "none",
  fontSize: "16px",
  height: "100%",
  fontFamily: "Poppins, sans-serif",
  width: "auto",
  minWidth: "unset",
};

export const MENU = [
  { name: "Buy", link: "/", external: false },
  { name: "Swap", link: "https://exchange.nftcloud.net/", external: true },
  { name: "Stake", link: "/stake", external: false },
  { name: "Stake NFT", link: "/pool", external: false },
  { name: "Stake CLOUD", link: "/staking-token", external: false },
  { name: "Cloud AI", link: "/cloud-ai", external: false },
  { name: "My Items", link: "/my-items", external: false },
  { name: "My Network", link: "/my-network", external: false },
];

function TransparentHeader(props: any) {
  const { isDark, isFixed, containerStyle } = props;
  // const [isOpenNoti, setIsOpenNoti] = useState(false);
  const [isMobileMenu, setIsOpenMobileMenu] = useState(false);
  const { pathname } = useLocation();

  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account as any
  );

  const getTextMenuColor = (link: string) => {
    if (isDark) {
      if (
        link === pathname ||
        (link === "/stake" && pathname === "/pool") ||
        (link === "/stake" && pathname === "/staking") ||
        (link === "/stake" && pathname === "/staking-token")
      )
        return "#FFB524";
      return "rgba(147, 143, 242, 0.49)";
    }
    if (
      link === pathname ||
      (link === "/stake" && pathname === "/pool") ||
      (link === "/stake" && pathname === "/staking") ||
      (link === "/stake" && pathname === "/staking-token")
    )
      return "global.menuText";
    return "rgba(147, 143, 242, 0.49)";
  };

  const endTime = 1691028000000;
  const [days, hours, minutes, seconds, countDown] = useCountdown(endTime);
  const isMobile = isMobileScreen();

  return (
    <Box
      height={{
        xl: "72px",
        base: "48px",
      }}
      backgroundColor={isDark ? "#181F51" : "transparent"}
      width="100%"
      padding="11px 30px"
      boxShadow={isDark ? "0px 4px 10px rgba(0, 0, 0, 0.08)" : "none"}
      position={isFixed ? "fixed" : "relative"}
      zIndex={1}
      {...containerStyle}
    >
      <Flex justify="space-between" height="100%" width="100%">
        <Flex align="center">
          <a href="https://nftcloud.net">
            <Image
              src={LogoImage}
              alt="logo"
              width={{
                xl: "180px",
                base: "90px",
              }}
              height={{
                xl: "48px",
                base: "24px",
              }}
            />
          </a>
          <Box
            height="20px"
            width="1.5px"
            // backgroundColor="global.dividerColor"
            background="transparent"
            margin="0px 21px 0px 21px"
            display={{
              xl: "block",
              base: "none",
            }}
          />
          <Grid
            gap="21px"
            templateColumns={`repeat(${MENU.length || 0}, auto)`}
            display={{
              xl: "grid",
              base: "none",
            }}
          >
            <Menu>
              {MENU.filter(
                (item) =>
                  item.link !== "/pool" && item.link !== "/staking-token"
              ).map((item) =>
                item?.link !== "/stake" ? (
                  <Button
                    key={item.name}
                    {...buttonMenuStyle}
                    color={getTextMenuColor(item?.link)}
                  >
                    {!item?.external && (
                      <Link
                        to={item?.link}
                        style={{
                          color:
                            item?.link === "/pool" &&
                            window.location.pathname === "/staking"
                              ? "#FFB524"
                              : "auto",
                          display: "flex",
                        }}
                      >
                        {item?.name}
                      </Link>
                    )}
                    {item?.external && <a href={item?.link}>{item?.name}</a>}
                  </Button>
                ) : (
                  <div key={item.name}>
                    <Button
                      {...buttonMenuStyle}
                      color={getTextMenuColor(item?.link)}
                    >
                      <MenuButton as={Link}>Staking</MenuButton>
                    </Button>
                    <MenuList>
                      <MenuItem as={Link} to="/pool">
                        Stake NFT
                      </MenuItem>
                      {countDown <= 0 && (
                        <MenuItem as={Link} to="/staking-token">
                          Stake CLOUD
                        </MenuItem>
                      )}
                    </MenuList>
                  </div>
                )
              )}
            </Menu>
          </Grid>
        </Flex>
        <Grid
          gap={{
            xl: "24px",
            base: "12px",
          }}
          templateColumns="repeat(3, auto)"
        >
          {!isMobile && (
            <Flex
              style={{
                color: "#fff",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img src="/favicon.png" width="20" height="20" />
                </div>{" "}
                NFTCloud <b>$0.20</b>
              </div>
            </Flex>
          )}
          <Link to="/staking">
            <Button
              {...buttonMenuStyle}
              width={{
                xl: "auto",
                base: "49px",
              }}
            >
              <Box position="relative">
                <Image
                  src={isDark ? CartLogoDark : CartLogo}
                  alt="cart"
                  width={{
                    xl: "32px",
                    base: "24px",
                  }}
                  height={{
                    xl: "32px",
                    base: "24px",
                  }}
                />
                {/* <Box
                minWidth="25px"
                height="20px"
                border="2px solid white"
                borderRadius="20px"
                backgroundColor="global.yellowOrange"
                fontSize="12px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="global.dividerColor"
                position="absolute"
                top="0"
                left={{
                  xl: "18px",
                  base: "18px",
                }}
                fontFamily="Poppins, sans-serif"
              >
                12
              </Box> */}
              </Box>
            </Button>
          </Link>

          {/* <Button
            {...buttonMenuStyle}
            onClick={() => {
              setIsOpenNoti(true);
            }}
          >
            <Image
              src={isDark ? MailUnreadDark : MailUnread}
              alt="mail unread"
              width={{
                xl: "32px",
                base: "24px",
              }}
              height={{
                xl: "32px",
                base: "24px",
              }}
            />
          </Button> */}
          <Button
            {...buttonMenuStyle}
            onClick={() => {
              setIsOpenMobileMenu(true);
            }}
            display={{
              xl: "none",
              base: "block",
            }}
          >
            <Image
              src={isDark ? menuDark : menu}
              alt="menu"
              width={{
                xl: "32px",
                base: "24px",
              }}
              height={{
                xl: "32px",
                base: "24px",
              }}
            />
          </Button>
          <Flex
            height="100%"
            alignItems="center"
            display={{
              xl: "flex",
              base: "none",
            }}
          >
            {!!login && !!logout && (
              <OrangeButton border="1px solid #181F51" height="32px">
                {account ? (
                  <Flex
                    fontSize="16px"
                    color="global.dividerColor"
                    fontWeight="600"
                    padding="4px 16px"
                    align="center"
                    justify="center"
                    onClick={() => {
                      onPresentAccountModal();
                    }}
                  >
                    <Image
                      src={wallet}
                      alt="wallet"
                      width="24px"
                      height="24px"
                      marginRight="10px"
                    />
                    <Text fontFamily="Poppins, sans-serif">
                      {accountEllipsis}
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    fontSize="16px"
                    color="global.dividerColor"
                    fontWeight="600"
                    padding="4px 16px"
                    align="center"
                    justify="center"
                    onClick={() => {
                      onPresentConnectModal();
                    }}
                  >
                    <Text fontFamily="Poppins, sans-serif">Connect Wallet</Text>
                  </Flex>
                )}
              </OrangeButton>
            )}
          </Flex>
        </Grid>
      </Flex>

      {/* {isOpenNoti && (
        <NotificationModal
          isOpen={isOpenNoti}
          onClose={() => {
            setIsOpenNoti(false);
          }}
        />
      )} */}
      {isMobileMenu && (
        <MobileMenuModal
          isOpen={isMobileMenu}
          onClose={() => {
            setIsOpenMobileMenu(false);
          }}
        />
      )}
    </Box>
  );
}

export default TransparentHeader;

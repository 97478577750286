import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { getNFTContract } from "../utils/contractHelpers";
import { multicall } from "src/utils/callHelpers";
import useWeb3 from "../hooks/useWeb3";
import BigNumber from "bignumber.js";
import nftAbi from "../config/abi/nft.json";
import { NFT_CONTRACT } from "src/config";
import axios, { AxiosError, Method } from "axios";
import { getNftInfo } from "src/utils/getNftInfo";

axios.defaults.baseURL = "https://nfts.nftcloud.net";

const NFTS_PER_PAGE = 50;

async function getNFTs(contract, ids) {
  const calls = [];
  const nfts = [];

  for (let i = 0; i < ids.length; i++) {
    calls.push({
      address: NFT_CONTRACT,
      name: "getNftInfo",
      params: [ids[i]],
    });
  }
  const _res = await multicall(nftAbi, calls);
  for (let i = 0; i < ids.length; i++) {
    const res = _res[i][0];

    const bunnyId = parseInt(res.bunnyId);
    const collection = bunnyId < 3 ? "Normal" : "V.I.P";
    const rank = ["Common", "Rare", "Legendary", "Common", "Rare", "Legendary"][
      bunnyId
    ];
    const price = new BigNumber(res.basePrice._hex);
    const lockUntil = parseInt(res.lockUntil);
    const subId = parseInt(res.subId);
    const { name, image } = await getNftInfo(ids[i]);
    const now = Math.floor(new Date().getTime() / 1000);
    nfts.push({
      name,
      isStaked: lockUntil > now,
      tokenURI: image,
      rank,
      collection,
      tokenId: ids[i],
      price,
      subId,
    });
  }
  return nfts;
}

export const useMyItems = (nftStatusFilter, setIsLoading, page, reloadTime) => {
  const [nfts, setNfts] = useState([]);
  const { account } = useWeb3React();

  const web3 = useWeb3();
  const contract = getNFTContract(web3);

  useEffect(() => {
    const fetchNFTs = async () => {
      setIsLoading(true);

      const ids = await contract.methods
        .tokenOfOwnerByRange(
          account,
          (page - 1) * NFTS_PER_PAGE,
          page * NFTS_PER_PAGE - 1
        )
        .call();

      // const ids = [1, 125, 151, 130, 55, 20];
      setNfts(await getNFTs(contract, ids));

      setIsLoading(false);
    };
    if (account) {
      fetchNFTs();
    }
  }, [account, web3, reloadTime]);

  if (nftStatusFilter === "Staked") {
    return nfts.filter((item) => item.isStaked);
  } else if (nftStatusFilter === "In Wallet") {
    return nfts.filter((item) => !item.isStaked);
  }
  return nfts;
};

/* eslint-disable react/no-unstable-nested-components */
import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import backIcon from "src/assets/chevron-back.png";
import yellowPiggy from "src/assets/mdi_piggy-bank.png";
import pricetags from "src/assets/pricetags.png";
import yellowBackIcon from "src/assets/orange-chevron-back.png";
import { isMobileScreen } from "src/utils/responsive";
import { styles } from "./styles";
import { getFullDisplayBalance } from "src/utils/formatBalance";
import { useTransferNFT } from "src/hooks/useTransferNFT";

const attributes = [
  {
    title: "Background",
  },
  {
    title: "Headwear",
  },
  {
    title: "Face",
  },
  {
    title: "Outfit",
  },
  {
    title: "Handhold",
  },
  {
    title: "Base",
  },
];

const StepOptions: Record<string, string> = {
  Attributes: "Attributes",
  InstantSell: "Instant Sell",
  Stake: "Stake",
  Transfer: "Transfer",
};

interface INFTDetails {
  step: string;
  // eslint-disable-next-line no-unused-vars
  setStep: (v: string) => void;
  onClose: () => void;
  item: any;
}

function getTotalItem(items) {
  if (items.collection === "Normal")
    return {
      Common: 6000, //4000
      Rare: 3000,
      Legendary: 2000,
    }[items.rank];
  return {
    Common: 1550, //550
    Rare: 600, //300
    Legendary: 150,
  }[items.rank];
}

function NFTDetails(props: INFTDetails) {
  const { step, setStep, onClose, item } = props;
  const [transferInProgress, setTransferInProgress] = useState(false);
  const [_to, setTo] = useState("");
  const { onTransferNFT } = useTransferNFT();

  function handleInputTransferChange(event) {
    let address = event.target.value;
    setTo(address);
  }

  const totalItem = getTotalItem(item);

  const displayAttributes = () => {
    return (
      <Box style={{ opacity: 0.2 }}>
        {attributes.map((att) => {
          return (
            <Box
              height="42px"
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="white"
              marginBottom="12px"
              padding="12px"
              borderRadius="10px"
              key={att.title}
            >
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                color="global.dividerColor"
              >
                {att.title}
              </Text>
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="18px"
                color="global.dividerColor"
              >
                ...10%
              </Text>
            </Box>
          );
        })}
      </Box>
    );
  };

  const displayTransfer = () => {
    return (
      <div style={{ padding: "30px 0" }}>
        <Box
          height="auto"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="white"
          marginBottom="12px"
          padding="12px"
          borderRadius="10px"
        >
          <Text
            fontSize="12px"
            fontWeight="500"
            lineHeight="18px"
            color="global.dividerColor"
            opacity="0.4"
          >
            Transfer to
          </Text>
          <div style={{ marginLeft: "10px" }}>
            <Input
              fontSize="16px"
              placeholder="0x..."
              value={_to}
              disabled={item.isStaked}
              onChange={handleInputTransferChange}
            />
          </div>
        </Box>
      </div>
    );
  };

  // const displayInstantSell = () => {
  //   return (
  //     <Box>
  //       <Box
  //         height="auto"
  //         width="100%"
  //         display="flex"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         backgroundColor="white"
  //         marginBottom="12px"
  //         padding="12px"
  //         borderRadius="10px"
  //       >
  //         <Text
  //           fontSize="12px"
  //           fontWeight="500"
  //           lineHeight="18px"
  //           color="global.dividerColor"
  //           opacity="0.4"
  //         >
  //           Receive
  //         </Text>
  //         <Box textAlign="right">
  //           <Text
  //             fontSize="24px"
  //             fontWeight="600"
  //             lineHeight="36px"
  //             color="global.dividerColor"
  //           >
  //             1000 SOL
  //           </Text>
  //           <Text
  //             fontSize="16px"
  //             fontWeight="400"
  //             lineHeight="24px"
  //             color="global.dividerColor"
  //           >
  //             ~$75
  //           </Text>
  //         </Box>
  //       </Box>
  //       <Box
  //         height="auto"
  //         width="100%"
  //         display="flex"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         backgroundColor="white"
  //         marginBottom="12px"
  //         padding="12px"
  //         borderRadius="10px"
  //       >
  //         <Box>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             Price
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             Free
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //           >
  //             Date
  //           </Text>
  //         </Box>

  //         <Box textAlign="right">
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             100 CLOUD ~$100
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             0.03 SOL
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //           >
  //             06/12/2022 - 09:41
  //           </Text>
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  // const displayStake = () => {
  //   return (
  //     <Box>
  //       <Box
  //         height="auto"
  //         width="100%"
  //         backgroundColor="white"
  //         marginBottom="12px"
  //         padding="12px"
  //         borderRadius="10px"
  //       >
  //         <Flex
  //           width="100%"
  //           justify="space-between"
  //           align="center"
  //           marginBottom="12px"
  //         >
  //           <Text
  //             fontSize="16px"
  //             fontWeight="500"
  //             lineHeight="24px"
  //             color="global.dividerColor"
  //           >
  //             Stake for
  //           </Text>
  //           <Box textAlign="right">
  //             <Text
  //               fontSize="16px"
  //               fontWeight="500"
  //               lineHeight="24px"
  //               color="global.dividerColor"
  //             >
  //               3 Month
  //             </Text>
  //           </Box>
  //         </Flex>
  //         <Box width="100%">
  //           <Flex>
  //             <Box>
  //               <Box
  //                 backgroundColor="global.yellowOrange"
  //                 width="18px"
  //                 height="18px"
  //                 borderRadius="50%"
  //               />
  //               <Box
  //                 marginTop="10px"
  //                 color="global.dividerColor"
  //                 fontSize="12px"
  //                 fontWeight="500"
  //                 lineHeight="18px"
  //               >
  //                 1M
  //               </Box>
  //             </Box>
  //             <Flex height="18px" flexGrow="1" align="center">
  //               <Box
  //                 height="4px"
  //                 width="100%"
  //                 background="rgba(24, 31, 81, 0.05)"
  //               />
  //             </Flex>
  //             <Box>
  //               <Box
  //                 backgroundColor="global.yellowOrange"
  //                 width="18px"
  //                 height="18px"
  //                 borderRadius="50%"
  //               />
  //               <Box
  //                 marginTop="10px"
  //                 color="global.dividerColor"
  //                 fontSize="12px"
  //                 fontWeight="500"
  //                 lineHeight="18px"
  //               >
  //                 2M
  //               </Box>
  //             </Box>
  //             <Flex height="18px" flexGrow="1" align="center">
  //               <Box
  //                 height="4px"
  //                 width="100%"
  //                 background="rgba(24, 31, 81, 0.05)"
  //               />
  //             </Flex>
  //             <Box>
  //               <Box
  //                 backgroundColor="global.yellowOrange"
  //                 width="18px"
  //                 height="18px"
  //                 borderRadius="50%"
  //               />
  //               <Box
  //                 marginTop="10px"
  //                 color="global.dividerColor"
  //                 fontSize="12px"
  //                 fontWeight="500"
  //                 lineHeight="18px"
  //               >
  //                 3M
  //               </Box>
  //             </Box>
  //             <Flex height="18px" flexGrow="1" align="center">
  //               <Box
  //                 height="4px"
  //                 width="100%"
  //                 background="rgba(24, 31, 81, 0.05)"
  //               />
  //             </Flex>
  //             <Box>
  //               <Box
  //                 backgroundColor="global.yellowOrange"
  //                 width="18px"
  //                 height="18px"
  //                 borderRadius="50%"
  //               />
  //               <Box
  //                 marginTop="10px"
  //                 color="global.dividerColor"
  //                 fontSize="12px"
  //                 fontWeight="500"
  //                 lineHeight="18px"
  //               >
  //                 4M
  //               </Box>
  //             </Box>
  //             <Flex height="18px" flexGrow="1" align="center">
  //               <Box
  //                 height="4px"
  //                 width="100%"
  //                 background="rgba(24, 31, 81, 0.05)"
  //               />
  //             </Flex>
  //             <Box>
  //               <Box
  //                 backgroundColor="global.yellowOrange"
  //                 width="18px"
  //                 height="18px"
  //                 borderRadius="50%"
  //               />
  //               <Box
  //                 marginTop="10px"
  //                 color="global.dividerColor"
  //                 fontSize="12px"
  //                 fontWeight="500"
  //                 lineHeight="18px"
  //               >
  //                 5M
  //               </Box>
  //             </Box>
  //           </Flex>
  //         </Box>
  //       </Box>
  //       <Box
  //         height="auto"
  //         width="100%"
  //         display="flex"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         backgroundColor="white"
  //         marginBottom="12px"
  //         padding="12px"
  //         borderRadius="10px"
  //       >
  //         <Box>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             Price
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             Duration
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //           >
  //             Unlock date
  //           </Text>
  //         </Box>

  //         <Box textAlign="right">
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             100 CLOUD ~$100
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             1 Month
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //           >
  //             06/12/2022 - 09:41
  //           </Text>
  //         </Box>
  //       </Box>
  //       <Box
  //         height="auto"
  //         width="100%"
  //         display="flex"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         backgroundColor="white"
  //         marginBottom="12px"
  //         padding="12px"
  //         borderRadius="10px"
  //       >
  //         <Box>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             APY
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //             marginBottom="12px"
  //           >
  //             Daily APY
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="500"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             opacity="0.4"
  //           >
  //             Total earn
  //           </Text>
  //         </Box>

  //         <Box textAlign="right">
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             20%
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //             marginBottom="12px"
  //           >
  //             0.64%
  //           </Text>
  //           <Text
  //             fontSize="12px"
  //             fontWeight="600"
  //             lineHeight="18px"
  //             color="global.dividerColor"
  //           >
  //             25 CLOUD
  //           </Text>
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  const displayFooter = () => {
    return (
      <Box
        bottom="64px"
        position={{
          xl: "unset",
          base: "fixed",
        }}
        left={{
          xl: "unset",
          base: "50%",
        }}
        transform={{
          xl: "unset",
          base: "translateX(-50%)",
        }}
      >
        <Flex
          align="center"
          justify="space-between"
          padding={{
            xl: "0px",
            base: "12px",
          }}
          borderRadius={{
            xl: "0px",
            base: "12px",
          }}
          width={{
            xl: "auto",
            base: "327px",
          }}
          background="white"
          boxShadow={{
            xl: "unset",
            base: "0px 4px 10px rgba(0, 0, 0, 0.08)",
          }}
        >
          {step === StepOptions.Attributes && (
            <>
              <Button
                variant="unstyled"
                border="1px solid #181F51"
                backgroundColor="global.yellowOrange"
                {...styles.defaultButton}
                flexBasis={{
                  xl: "397px",
                  base: "118px",
                }}
                marginRight="10px"
                type="button"
                flexGrow={{
                  xl: "unset",
                  base: "1",
                }}
              >
                <Image
                  src={pricetags}
                  alt="pricetags"
                  width="16px"
                  height="16px"
                  marginRight="8px"
                />
                <Text
                  fontSize="16px"
                  color="global.dividerColor"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Instant Sell
                </Text>
              </Button>
              <Button
                variant="unstyled"
                {...styles.defaultButton}
                padding="8px 16px"
                backgroundColor="global.dividerColor"
                color="global.yellowOrange"
                type="button"
              >
                <Image
                  src={yellowPiggy}
                  alt="pig white"
                  width="16px"
                  height="16px"
                  marginRight="8px"
                />
                <Text
                  fontSize="16px"
                  color="white"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Unstake
                </Text>
              </Button>
            </>
          )}
          {step === StepOptions.Transfer && (
            <>
              <Button
                variant="unstyled"
                border="1px solid #181F51"
                backgroundColor="global.yellowOrange"
                {...styles.defaultButton}
                type="button"
                flexGrow="1"
                onClick={async () => {
                  setTransferInProgress(true);
                  try {
                    await onTransferNFT(_to, item.tokenId);
                    setTransferInProgress(false);
                  } catch (e) {
                    setTransferInProgress(false);
                  }
                }}
                disabled={
                  transferInProgress || item.isStaked || _to.length === 0
                }
              >
                {transferInProgress ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    style={{ color: "#181F51" }}
                  ></div>
                ) : (
                  <>
                    <Image
                      src={pricetags}
                      alt="pricetags"
                      width="16px"
                      height="16px"
                      marginRight="8px"
                    />
                    <Text
                      fontSize="16px"
                      color="global.dividerColor"
                      lineHeight="24px"
                      fontWeight="600"
                    >
                      Transfer
                    </Text>
                  </>
                )}
              </Button>
            </>
          )}
          {/* {step === StepOptions.InstantSell && (
            <>
              <Button
                variant="unstyled"
                border="1px solid #181F51"
                backgroundColor="global.yellowOrange"
                {...styles.defaultButton}
                flexBasis={{
                  xl: "397px",
                  base: "118px",
                }}
                marginRight="10px"
                type="button"
                flexGrow={{
                  xl: "unset",
                  base: "1",
                }}
              >
                <Image
                  src={pricetags}
                  alt="pricetags"
                  width="16px"
                  height="16px"
                  marginRight="8px"
                />
                <Text
                  fontSize="16px"
                  color="global.dividerColor"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Confirm
                </Text>
              </Button>
              <Button
                variant="unstyled"
                {...styles.defaultButton}
                padding="8px 16px"
                backgroundColor="global.pink"
                type="button"
              >
                <Text
                  fontSize="16px"
                  color="white"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Cancel
                </Text>
              </Button>
            </>
          )}
          {step === StepOptions.Stake && (
            <>
              <Button
                variant="unstyled"
                border="1px solid #181F51"
                backgroundColor="global.yellowOrange"
                {...styles.defaultButton}
                flexBasis={{
                  xl: "397px",
                  base: "118px",
                }}
                marginRight="10px"
                type="button"
                flexGrow={{
                  xl: "unset",
                  base: "1",
                }}
              >
                <Image
                  src={pricetags}
                  alt="pricetags"
                  width="16px"
                  height="16px"
                  marginRight="8px"
                />
                <Text
                  fontSize="16px"
                  color="global.dividerColor"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Confirm
                </Text>
              </Button>
              <Button
                variant="unstyled"
                {...styles.defaultButton}
                padding="8px 16px"
                backgroundColor="global.pink"
                type="button"
              >
                <Text
                  fontSize="16px"
                  color="white"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  Cancel
                </Text>
              </Button>
            </>
          )} */}
        </Flex>
      </Box>
    );
  };

  const headerLink = (text, currentStep) => {
    return (
      <Text
        marginRight={{
          xl: "12px",
          base: "0px",
        }}
        fontFamily="Clash Display"
        color="global.dividerColor"
        fontWeight={{
          xl: step === currentStep ? "600" : "300",
          base: 600,
        }}
        {...(step === currentStep ? {} : { opacity: "0.4" })}
        fontSize={{
          xl: "20px",
          base: "16px",
        }}
        lineHeight="140%"
        cursor="pointer"
        onClick={() => {
          setStep(currentStep);
        }}
        flexBasis={{
          xl: "unset",
          base: "33.33%",
        }}
        textAlign={{
          xl: "unset",
          base: "center",
        }}
        background={{
          xl: "unset",
          base: step === currentStep ? "global.yellowOrange" : "transparent",
        }}
        display={{
          xl: "uset",
          base: "flex",
        }}
        justifyContent={{
          xl: "unset",
          base: "center",
        }}
        alignItems={{
          xl: "unset",
          base: "center",
        }}
        borderRadius={{
          xl: "unset",
          base: step === currentStep ? "8px" : "0px",
        }}
      >
        {text}
      </Text>
    );
  };

  const isMobile = isMobileScreen();

  return (
    <Box
      width="100%"
      height="100%"
      background="white"
      borderRadius={{
        xl: "unset",
        base: "20px",
      }}
      overflow="hidden"
    >
      <Flex
        height="72px"
        width="100%"
        padding={{
          xl: "20px 30px",
          base: "10px 20px",
        }}
        justify="start"
        align="center"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.08)"
        background={{
          xl: "white",
          base: "#181F51",
        }}
      >
        <Image
          alt="icon"
          src={isMobile ? yellowBackIcon : backIcon}
          width="32px"
          height="32px"
          marginRight="20px"
          onClick={onClose}
          cursor="pointer"
        />
        <Text
          fontFamily="Clash Display"
          fontWeight="600"
          fontSize="20px"
          lineHeight="24.6px"
          color={{
            xl: "global.dividerColor",
            base: "global.yellowOrange",
          }}
        >
          {isMobile ? "My Items" : "My Items"}
        </Text>
      </Flex>
      <Box
        padding={{
          xl: "30px",
          base: "0px",
        }}
      >
        <Box
          padding={{
            xl: "0px",
            base: "12px 20px 0px 20px",
          }}
        >
          <Box marginBottom="12px">
            <Text
              fontFamily="Clash Display"
              color="global.dividerColor"
              fontSize="26px"
              lineHeight="32px"
              fontWeight="600"
            >
              {item.name}
              <span
                style={{
                  display: "inline-block",
                  marginLeft: "10px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                  lineHeight: "39px",
                  color: "#FFA800",
                }}
              >
                #{item.tokenId}
              </span>
            </Text>
          </Box>
          <Flex
            align="center"
            justify="space-between"
            marginBottom={{
              xl: "30px",
              base: "12px",
            }}
          >
            <Box>
              <Text
                color={
                  { Common: "#938FF2", Rare: "#01B976", Legendary: "#FFA800" }[
                    item.rank
                  ]
                }
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
              >
                {item.rank}
              </Text>
              <Text
                color="global.dividerColor"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
              >
                {item.subId} / {totalItem}
              </Text>
            </Box>
            <Box textAlign="right">
              <Text
                color="global.dividerColor"
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
              >
                Price
              </Text>
              <Text
                color="global.dividerColor"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
              >
                {getFullDisplayBalance(item.price)} USDT
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          background="linear-gradient(0deg, rgba(24, 31, 81, 0.05), rgba(24, 31, 81, 0.05)), #FFFFFF"
          borderRadius={{
            xl: "10px",
            base: "0px",
          }}
          marginBottom={{
            xl: "30px",
            base: "0px",
          }}
          padding={{
            xl: "12pxpx",
            base: "20px",
          }}
        >
          <Flex
            marginBottom="12px"
            background={{
              xl: "unset",
              base: "rgba(255, 181, 36, 0.4)",
            }}
            borderRadius={{
              xl: "unset",
              base: "8px",
            }}
            height={{
              xl: "auto",
              base: "30px",
            }}
          >
            {headerLink("Attribute", StepOptions.Attributes)}
            {headerLink("Transfer", StepOptions.Transfer)}
            {/* {headerLink("Instant Sell", StepOptions.InstantSell)} */}
          </Flex>
          {step === StepOptions.Attributes && displayAttributes()}
          {step === StepOptions.Transfer && displayTransfer()}
          {/* {step === StepOptions.InstantSell && displayInstantSell()} */}
        </Box>
        {displayFooter()}
      </Box>
    </Box>
  );
}

export default NFTDetails;

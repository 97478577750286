import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import useRefresh from "./useRefresh";
import { getBep20Contract } from "../utils/contractHelpers";
import { SALE_NFT_CONTRACT } from "src/config";
import useWeb3 from "../hooks/useWeb3";

const BIG_ZERO = new BigNumber(0);

export const useAllowance = (tokenAddress, spender = SALE_NFT_CONTRACT) => {
  const [allowance, setAllowance] = useState(BIG_ZERO);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const contract = getBep20Contract(tokenAddress, web3);
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchAllowance = async () => {
      const res = await contract.methods.allowance(account, spender).call();
      setAllowance(new BigNumber(res));
    };
    if (account) {
      fetchAllowance();
    }
  }, [account, contract, fastRefresh]);

  return allowance;
};

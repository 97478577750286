import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import uniqueId from "lodash/uniqueId";
import { SvgProps } from "../types";

const Svg = styled.svg<SvgProps>`
  align-self: center; // Safari fix
  flex-shrink: 0;
  .st0 {
    fill: #49a341;
  }
  .st1 {
    fill: url(#SVGID_1_);
  }
  .st2 {
    fill: url(#SVGID_2_);
  }
  .st3 {
    fill: url(#SVGID_3_);
  }
  .st4 {
    fill: url(#SVGID_4_);
  }
  .st5 {
    fill: url(#SVGID_5_);
  }
  .st6 {
    fill: url(#SVGID_6_);
  }
  .st7 {
    fill: url(#SVGID_7_);
  }
  .st8 {
    fill: url(#SVGID_8_);
  }
  .st9 {
    fill: url(#SVGID_9_);
  }
  .st10 {
    fill: url(#SVGID_10_);
  }
  .st11 {
    fill: url(#SVGID_11_);
  }
  .st12 {
    fill: url(#SVGID_12_);
  }
  .st13 {
    fill: url(#SVGID_13_);
  }
  .st14 {
    fill: url(#SVGID_14_);
  }
  .st15 {
    fill: url(#SVGID_15_);
  }
  .st16 {
    fill: url(#SVGID_16_);
  }
  .st17 {
    fill: url(#SVGID_17_);
  }
  .st18 {
    fill: url(#SVGID_18_);
  }
  .st19 {
    fill: url(#SVGID_19_);
  }
  .st20 {
    fill: url(#SVGID_20_);
  }
  .st21 {
    fill: url(#SVGID_21_);
  }
  .st22 {
    fill: url(#SVGID_22_);
  }
  .st23 {
    fill: url(#SVGID_23_);
  }
  .st24 {
    fill: url(#SVGID_24_);
  }
  .st25 {
    fill: url(#SVGID_25_);
  }
  .st26 {
    fill: url(#SVGID_26_);
  }
  .st27 {
    fill: #b7d972;
  }
  .st28 {
    fill: url(#SVGID_27_);
  }
  .st29 {
    fill: url(#SVGID_28_);
  }
  .st30 {
    fill: url(#SVGID_29_);
  }
  .st31 {
    fill: url(#SVGID_30_);
  }
  .st32 {
    fill: #9bc1f9;
  }
  .st33 {
    fill: #edfdfc;
  }
  .st34 {
    fill: url(#SVGID_31_);
  }
  .st35 {
    fill: url(#SVGID_32_);
  }
  .st36 {
    fill: url(#SVGID_33_);
  }
  .st37 {
    fill: #f47257;
  }
  .st38 {
    fill: url(#SVGID_34_);
  }
  .st39 {
    fill: #50bfa5;
  }
  .st40 {
    fill: #3c8f7c;
  }
  .st41 {
    fill: url(#SVGID_35_);
  }
  .st42 {
    fill: url(#SVGID_36_);
  }
  .st43 {
    fill: url(#SVGID_37_);
  }
  .st44 {
    fill: url(#SVGID_38_);
  }
  .st45 {
    fill: url(#SVGID_39_);
  }
  .st46 {
    fill: url(#SVGID_40_);
  }
  .st47 {
    fill: url(#SVGID_41_);
  }
  .st48 {
    fill: url(#SVGID_42_);
  }
  .st49 {
    fill: #847d3d;
  }
  .st50 {
    fill: url(#SVGID_43_);
  }
  .st51 {
    fill: #ffffff;
  }
  .st52 {
    fill: url(#SVGID_44_);
  }
  .st53 {
    fill: url(#SVGID_45_);
  }
  .st54 {
    fill: url(#SVGID_46_);
  }
  .st55 {
    fill: url(#SVGID_47_);
  }
  .st56 {
    fill: url(#SVGID_48_);
  }
  .st57 {
    fill: url(#SVGID_49_);
  }
  .st58 {
    fill: url(#SVGID_50_);
  }
  .st59 {
    fill: url(#SVGID_51_);
  }
  .st60 {
    fill: url(#SVGID_52_);
  }
  .st61 {
    fill: url(#SVGID_53_);
  }
  .st62 {
    fill: url(#SVGID_54_);
  }
  .st63 {
    fill: url(#SVGID_55_);
  }
  .st64 {
    fill: url(#SVGID_56_);
  }
  .st65 {
    fill: url(#SVGID_57_);
  }
  .st66 {
    fill: #13375b;
  }
  .st67 {
    fill: #473b1e;
  }
  .st68 {
    fill: url(#SVGID_58_);
  }
  .st69 {
    fill: url(#SVGID_59_);
  }
  .st70 {
    fill: url(#SVGID_60_);
  }
  .st71 {
    fill: url(#SVGID_61_);
  }
  .st72 {
    fill: url(#SVGID_62_);
  }
  .st73 {
    fill: url(#SVGID_63_);
  }
  .st74 {
    fill: url(#SVGID_64_);
  }
  .st75 {
    fill: url(#SVGID_65_);
  }
  .st76 {
    fill: url(#SVGID_66_);
  }
  .st77 {
    fill: url(#SVGID_67_);
  }
  .st78 {
    fill: url(#SVGID_68_);
  }
  .st79 {
    fill: url(#SVGID_69_);
  }
  .st80 {
    fill: #f7cc7f;
  }
  .st81 {
    fill: #fae0b2;
  }
  .st82 {
    fill: url(#SVGID_70_);
  }
  .st83 {
    fill: #f7a491;
  }
  .st84 {
    fill: url(#SVGID_71_);
  }
  .st85 {
    fill: url(#SVGID_72_);
  }
  .st86 {
    fill: #48433e;
  }
  .st87 {
    fill: #35312d;
  }
  .st88 {
    fill: #5d3f24;
  }
  .st89 {
    fill: #52351c;
  }
  .st90 {
    fill: #735236;
  }
  .st91 {
    fill: #dbdbdb;
  }
  .st92 {
    fill: #e3e3e3;
  }
  .st93 {
    fill: #fbfbfb;
  }
  .st94 {
    fill: #fcc7aa;
  }
  .st95 {
    fill: #ffd6bf;
  }
  .st96 {
    fill: #e7e7e7;
  }
  .st97 {
    fill: #ececec;
  }
  .st98 {
    fill: #c4c4c4;
  }
  .st99 {
    fill: #454545;
  }
  .st100 {
    fill: #696969;
  }
  .st101 {
    fill: #fad3be;
  }
  .st102 {
    fill: #4a2727;
  }
  .st103 {
    fill: #dcdbca;
  }
  .st104 {
    fill: #f2b33f;
  }
  .st105 {
    fill: #cd951a;
  }
  .st106 {
    fill: #ffde5c;
  }
  .st107 {
    fill: #fccd49;
  }
  .st108 {
    fill: #d47e15;
  }
  .st109 {
    fill: #efae36;
  }
  .st110 {
    fill: #ecac32;
  }
  .st111 {
    fill: #f7b943;
  }
  .st112 {
    fill: #eca336;
  }
  .st113 {
    fill: #ffc84f;
  }
  .st114 {
    fill: #ca8d17;
  }
  .st115 {
    fill: #e7a630;
  }
  .st116 {
    fill: #a35f0b;
  }
  .st117 {
    fill: #ffe685;
  }
  .st118 {
    fill: #f2cc45;
  }
  .st119 {
    fill: #bd7e17;
  }
  .st120 {
    fill: #dbac3a;
  }
  .st121 {
    fill: #e6bb42;
  }
  .st122 {
    fill: #ffe152;
  }
  .st123 {
    fill: #ffef68;
  }
  .st124 {
    fill: #f7c840;
  }
  .st125 {
    fill: #f2ba3a;
  }
  .st126 {
    fill: #fcd645;
  }
  .st127 {
    fill: #d9952c;
  }
  .st128 {
    fill: #bd7717;
  }
  .st129 {
    enable-background: new;
  }
  .st130 {
    clippath: url(#SVGID_74_);
    enable-background: new;
  }
  .st131 {
    fill: #d5aa39;
  }
  .st132 {
    fill: #d8ad3b;
  }
  .st133 {
    fill: #dab13d;
  }
  .st134 {
    fill: #ddb53e;
  }
  .st135 {
    fill: #e0b940;
  }
  .st136 {
    fill: #d2a638;
  }
  .st137 {
    fill: #cfa236;
  }
  .st138 {
    fill: #cc9e34;
  }
  .st139 {
    fill: #c99b33;
  }
  .st140 {
    fill: #c79731;
  }
  .st141 {
    fill: #c49330;
  }
  .st142 {
    fill: #c1902e;
  }
  .st143 {
    fill: #be8d2c;
  }
  .st144 {
    clippath: url(#SVGID_76_);
    enable-background: new;
  }
  .st145 {
    clippath: url(#SVGID_78_);
    enable-background: new;
  }
  .st146 {
    clippath: url(#SVGID_80_);
    enable-background: new;
  }
  .st147 {
    fill: #e4bd42;
  }
  .st148 {
    fill: #db8730;
  }
  .st149 {
    clippath: url(#SVGID_82_);
    enable-background: new;
  }
  .st150 {
    fill: #d46b15;
  }
  .st151 {
    clippath: url(#SVGID_84_);
    enable-background: new;
  }
  .st152 {
    clippath: url(#SVGID_86_);
    enable-background: new;
  }
  .st153 {
    clippath: url(#SVGID_88_);
    enable-background: new;
  }
  .st154 {
    fill: #ffdd85;
  }
  .st155 {
    fill: #baa37a;
  }
  .st156 {
    clippath: url(#SVGID_90_);
    enable-background: new;
  }
  .st157 {
    clippath: url(#SVGID_92_);
    enable-background: new;
  }
  .st158 {
    clippath: url(#SVGID_94_);
    enable-background: new;
  }
  .st159 {
    clippath: url(#SVGID_96_);
    enable-background: new;
  }
  .st160 {
    fill: #ba9e7a;
  }
  .st161 {
    clippath: url(#SVGID_98_);
    enable-background: new;
  }
  .st162 {
    clippath: url(#SVGID_100_);
    enable-background: new;
  }
  .st163 {
    clippath: url(#SVGID_102_);
    enable-background: new;
  }
  .st164 {
    clippath: url(#SVGID_104_);
    enable-background: new;
  }
  .st165 {
    clippath: url(#SVGID_106_);
    enable-background: new;
  }
  .st166 {
    clippath: url(#SVGID_108_);
    enable-background: new;
  }
  .st167 {
    clippath: url(#SVGID_110_);
    enable-background: new;
  }
  .st168 {
    clippath: url(#SVGID_112_);
    enable-background: new;
  }
  .st169 {
    fill: #f2ce45;
  }
  .st170 {
    fill: #bd8017;
  }
  .st171 {
    fill: #dbad3a;
  }
  .st172 {
    fill: #e6bd42;
  }
  .st173 {
    fill: #efb636;
  }
  .st174 {
    fill: #fff168;
  }
  .st175 {
    fill: #fffa68;
  }
  .st176 {
    fill: #ecb432;
  }
  .st177 {
    fill: #f7c143;
  }
  .st178 {
    fill: #d9982c;
  }
  .st179 {
    fill: #bd7b17;
  }
  .st180 {
    fill: #ffe55c;
  }
  .st181 {
    fill: #a3670b;
  }
  .st182 {
    fill: #e7ae30;
  }
  .st183 {
    fill: #ffeb85;
  }
  .st184 {
    clippath: url(#SVGID_114_);
    enable-background: new;
  }
  .st185 {
    clippath: url(#SVGID_116_);
    enable-background: new;
  }
  .st186 {
    clippath: url(#SVGID_118_);
    enable-background: new;
  }
  .st187 {
    clippath: url(#SVGID_120_);
    enable-background: new;
  }
  .st188 {
    clippath: url(#SVGID_122_);
    enable-background: new;
  }
  .st189 {
    clippath: url(#SVGID_124_);
    enable-background: new;
  }
  .st190 {
    clippath: url(#SVGID_126_);
    enable-background: new;
  }
  .st191 {
    clippath: url(#SVGID_128_);
    enable-background: new;
  }
  .st192 {
    clippath: url(#SVGID_130_);
    enable-background: new;
  }
  .st193 {
    clippath: url(#SVGID_132_);
    enable-background: new;
  }
  .st194 {
    clippath: url(#SVGID_134_);
    enable-background: new;
  }
  .st195 {
    clippath: url(#SVGID_136_);
    enable-background: new;
  }
  .st196 {
    clippath: url(#SVGID_138_);
    enable-background: new;
  }
  .st197 {
    clippath: url(#SVGID_140_);
    enable-background: new;
  }
  .st198 {
    clippath: url(#SVGID_142_);
    enable-background: new;
  }
  .st199 {
    clippath: url(#SVGID_144_);
    enable-background: new;
  }
  .st200 {
    clippath: url(#SVGID_146_);
    enable-background: new;
  }
  .st201 {
    clippath: url(#SVGID_148_);
    enable-background: new;
  }
  .st202 {
    clippath: url(#SVGID_150_);
    enable-background: new;
  }
  .st203 {
    clippath: url(#SVGID_152_);
    enable-background: new;
  }
  .st204 {
    clippath: url(#SVGID_154_);
    enable-background: new;
  }
  .st205 {
    clippath: url(#SVGID_156_);
    enable-background: new;
  }
  .st206 {
    clippath: url(#SVGID_158_);
    enable-background: new;
  }
  .st207 {
    clippath: url(#SVGID_160_);
    enable-background: new;
  }
  .st208 {
    clippath: url(#SVGID_162_);
    enable-background: new;
  }
  .st209 {
    clippath: url(#SVGID_164_);
    enable-background: new;
  }
  .st210 {
    clippath: url(#SVGID_166_);
    enable-background: new;
  }
  .st211 {
    clippath: url(#SVGID_168_);
    enable-background: new;
  }
  .st212 {
    clippath: url(#SVGID_170_);
    enable-background: new;
  }
  .st213 {
    clippath: url(#SVGID_172_);
    enable-background: new;
  }
  .st214 {
    clippath: url(#SVGID_174_);
    enable-background: new;
  }
  .st215 {
    clippath: url(#SVGID_176_);
    enable-background: new;
  }
  .st216 {
    clippath: url(#SVGID_178_);
    enable-background: new;
  }
  .st217 {
    clippath: url(#SVGID_180_);
    enable-background: new;
  }
  .st218 {
    clippath: url(#SVGID_182_);
    enable-background: new;
  }
  .st219 {
    clippath: url(#SVGID_184_);
    enable-background: new;
  }
  .st220 {
    fill: #d3d0b7;
  }
  .st221 {
    fill: url(#XMLID_613_);
  }
  .st222 {
    fill: #2b2b2b;
  }
  .st223 {
    opacity: 0.74;
    fill: url(#XMLID_614_);
  }
  .st224 {
    opacity: 0.4;
    fill: url(#XMLID_615_);
  }
  .st225 {
    clippath: url(#XMLID_616_);
    fill: url(#XMLID_617_);
  }
  .st226 {
    clippath: url(#XMLID_616_);
    fill: url(#XMLID_618_);
  }
  .st227 {
    opacity: 0.45;
    clippath: url(#XMLID_616_);
    fill: url(#XMLID_619_);
  }
  .st228 {
    opacity: 0.45;
    clippath: url(#XMLID_616_);
    fill: url(#XMLID_620_);
  }
  .st229 {
    fill: #fff6e3;
  }
  .st230 {
    fill: #f71c28;
  }
  .st231 {
    fill: #e70507;
  }
  .st232 {
    opacity: 0.78;
    fill: url(#XMLID_621_);
  }
  .st233 {
    fill: url(#XMLID_622_);
  }
  .st234 {
    fill: url(#XMLID_623_);
  }
  .st235 {
    fill: url(#XMLID_624_);
  }
  .st236 {
    fill: url(#XMLID_625_);
  }
  .st237 {
    fill: url(#XMLID_646_);
  }
  .st238 {
    fill: url(#XMLID_647_);
  }
  .st239 {
    fill: url(#XMLID_648_);
  }
  .st240 {
    opacity: 0.58;
    fill: url(#XMLID_682_);
  }
  .st241 {
    fill: url(#XMLID_683_);
  }
  .st242 {
    fill: url(#XMLID_684_);
  }
  .st243 {
    fill: url(#XMLID_685_);
  }
  .st244 {
    fill: url(#XMLID_686_);
  }
  .st245 {
    fill: url(#XMLID_687_);
  }
  .st246 {
    fill: url(#XMLID_688_);
  }
  .st247 {
    fill: url(#XMLID_689_);
  }
  .st248 {
    fill: url(#XMLID_690_);
  }
  .st249 {
    fill: url(#XMLID_691_);
  }
  .st250 {
    fill: url(#XMLID_692_);
  }
  .st251 {
    fill: url(#XMLID_693_);
  }
  .st252 {
    fill: url(#XMLID_694_);
  }
  .st253 {
    fill: url(#XMLID_695_);
  }
  .st254 {
    fill: url(#XMLID_696_);
  }
  .st255 {
    fill: url(#XMLID_697_);
  }
  .st256 {
    fill: url(#XMLID_698_);
  }
  .st257 {
    fill: url(#XMLID_699_);
  }
  .st258 {
    fill: url(#XMLID_700_);
  }
  .st259 {
    fill: url(#XMLID_701_);
  }
  .st260 {
    fill: url(#XMLID_702_);
  }
  .st261 {
    fill: url(#XMLID_703_);
  }
  .st262 {
    fill: url(#XMLID_704_);
  }
  .st263 {
    fill: url(#XMLID_705_);
  }
  .st264 {
    fill: url(#XMLID_706_);
  }
  .st265 {
    fill: url(#XMLID_707_);
  }
  .st266 {
    fill: url(#XMLID_708_);
  }
  .st267 {
    fill: url(#XMLID_709_);
  }
  .st268 {
    fill: url(#XMLID_710_);
  }
  .st269 {
    fill: url(#XMLID_711_);
  }
  .st270 {
    fill: url(#XMLID_712_);
  }
  .st271 {
    fill: url(#XMLID_713_);
  }
  .st272 {
    fill: url(#XMLID_714_);
  }
  .st273 {
    fill: url(#XMLID_715_);
  }
  .st274 {
    fill: url(#XMLID_716_);
  }
  .st275 {
    fill: url(#XMLID_717_);
  }
  .st276 {
    fill: url(#XMLID_718_);
  }
  .st277 {
    fill: url(#XMLID_719_);
  }
  .st278 {
    fill: url(#XMLID_720_);
  }
  .st279 {
    fill: url(#XMLID_721_);
  }
  .st280 {
    fill: url(#XMLID_722_);
  }
  .st281 {
    fill: url(#XMLID_723_);
  }
  .st282 {
    fill: url(#XMLID_724_);
  }
  .st283 {
    fill: url(#XMLID_725_);
  }
  .st284 {
    fill: url(#XMLID_726_);
  }
  .st285 {
    fill: url(#XMLID_727_);
  }
  .st286 {
    fill: url(#XMLID_728_);
  }
  .st287 {
    fill: url(#XMLID_729_);
  }
  .st288 {
    fill: url(#XMLID_730_);
  }
  .st289 {
    fill: url(#XMLID_731_);
  }
  .st290 {
    fill: url(#XMLID_732_);
  }
  .st291 {
    fill: url(#XMLID_733_);
  }
  .st292 {
    fill: url(#XMLID_734_);
  }
  .st293 {
    fill: url(#XMLID_735_);
  }
  .st294 {
    fill: url(#XMLID_736_);
  }
  .st295 {
    fill: url(#XMLID_737_);
  }
  .st296 {
    opacity: 0.62;
    clippath: url(#XMLID_738_);
    fill: url(#XMLID_739_);
  }
  .st297 {
    opacity: 0.84;
    clippath: url(#XMLID_740_);
    fill: url(#XMLID_741_);
  }
  .st298 {
    opacity: 0.84;
    clippath: url(#XMLID_740_);
    fill: url(#XMLID_742_);
  }
  .st299 {
    fill: url(#XMLID_743_);
  }
  .st300 {
    opacity: 0.86;
    fill: url(#XMLID_744_);
  }
  .st301 {
    fill: #fabd9c;
  }
  .st302 {
    fill: #e7e1c2;
  }
  .st303 {
    fill: #66bb46;
  }
  .st304 {
    fill: #149c54;
  }
  .st305 {
    fill: #002500;
  }
  .st306 {
    fill: #00a64a;
  }
  .st307 {
    fill: #002700;
  }
  .st308 {
    fill: #f89c2b;
  }
  .st309 {
    fill: #005d29;
  }
  .st310 {
    fill: #007f37;
  }
  .st311 {
    fill: #53b144;
  }
  .st312 {
    fill: #fbaf29;
  }
  .st313 {
    fill: #f16522;
  }
  .st314 {
    fill: #ddc2b8;
  }
  .st315 {
    fill: #521a0f;
  }
  .st316 {
    fill: #c2bea5;
  }
  .st317 {
    fill: #404041;
  }
  .st318 {
    fill: #4a4a4c;
  }
  .st319 {
    fill: #a6a8ab;
  }
  .st320 {
    fill: #e6e7e8;
  }
  .st321 {
    fill: #545456;
  }
  .st322 {
    fill: #3e3e3f;
  }
  .st323 {
    fill: #cd8b1e;
  }
  .st324 {
    fill: #ffdd15;
  }
  .st325 {
    fill: #faa925;
  }
  .st326 {
    fill: #b97f19;
  }
  .st327 {
    fill: #ead6c4;
  }
  .st328 {
    fill: url(#SVGID_185_);
  }
  .st329 {
    fill: url(#SVGID_186_);
  }
  .st330 {
    fill: url(#SVGID_187_);
  }
  .st331 {
    fill: url(#SVGID_188_);
  }
  .st332 {
    fill: url(#SVGID_189_);
  }
  .st333 {
    fill: url(#SVGID_190_);
  }
  .st334 {
    fill: url(#SVGID_191_);
  }
  .st335 {
    fill: url(#SVGID_192_);
  }
  .st336 {
    fill: url(#SVGID_193_);
  }
  .st337 {
    fill: url(#SVGID_194_);
  }
  .st338 {
    fill: url(#SVGID_195_);
  }
  .st339 {
    fill: url(#SVGID_196_);
  }
  .st340 {
    fill: url(#SVGID_197_);
  }
  .st341 {
    fill: url(#SVGID_198_);
  }
  .st342 {
    fill: url(#SVGID_199_);
  }
  .st343 {
    fill: #75bd38;
  }
  .st344 {
    fill: #474656;
  }
  .st345 {
    fill: #e5e5e5;
  }
  .st346 {
    opacity: 0.5;
    fill: #598e38;
  }
  .st347 {
    opacity: 0.55;
    fill: #598e38;
  }
  .st348 {
    fill: #d21b00;
  }
  .st349 {
    fill: #f65138;
  }
  .st350 {
    opacity: 0.67;
    fill: #b35c45;
  }
  .st351 {
    fill: #5c5248;
  }
  .st352 {
    fill: #51555d;
  }
  .st353 {
    fill: #f86f5b;
  }
  .st354 {
    opacity: 0.15;
  }
  ${space}
`;

Svg.defaultProps = {
  color: "text",
  width: "20px",
  xmlns: "http://www.w3.org/2000/svg",
  spin: false,
};

const Icon: React.FC<SvgProps> = (props) => {
  const id = uniqueId("logo");
  return (
    <Svg viewBox="0 0 59 64" {...props}>
      <g clipPath={`url(#clip2021${id})`}>
        <path
          d="M48.3294 11.0393V33.9963L30.0037 23.1167L29.4448 22.7773L28.9258 23.0967L10.141 34.2358V11.0393L0 17.068V50.8247L0.419214 50.5652V50.8247L29.4448 33.577L58.4903 50.8247V40.005V17.068L48.3294 11.0393Z"
          fill="#49A341"
        />
        <path
          d="M29.4448 0L18.7049 6.36806V16.8284H19.723L29.4448 11.0393L39.1865 16.8284H39.5458V5.98877L29.4448 0Z"
          fill="#49A341"
        />
        <path
          d="M29.4448 58.1709L19.8827 64L11.0194 57.7517L29.4249 46.5128L47.9701 57.8116L39.0268 64L29.4448 58.1709Z"
          fill="#49A341"
        />
        <path
          d="M29.5047 22.8172L29.4448 22.7773L28.9258 23.0967L10.141 34.2358V16.8284H0.419214L0 17.068V50.8247L0.419214 50.5652V50.8247L29.4448 33.577L29.5047 33.617V22.8172Z"
          fill="url(#logo_paint0_linear)"
        />
        <path
          d="M48.3294 18.3656V33.9963L30.0038 23.1167L29.5047 22.8173V33.617L58.4903 50.8247V40.005V18.3656H48.3294Z"
          fill="#49A341"
        />
        <path
          d="M10.1809 45.0156V34.2158L10.141 34.2358V11.0393L0 17.068V50.8247L0.419214 50.5652V50.8247L10.1809 45.0156Z"
          fill="url(#logo_paint1_linear)"
        />
        <path
          d="M48.3294 33.9963L48.2495 33.9364V44.7361L58.4903 50.8247V40.005V17.068L48.3294 11.0393V33.9963Z"
          fill="url(#logo_paint2_linear)"
        />
        <path
          d="M29.2452 0.119751L18.7049 6.36803V16.8284H19.723L29.2452 11.1591V0.119751Z"
          fill="url(#logo_paint3_linear)"
        />
        <path
          d="M29.5047 46.5527L29.4249 46.5128L11.0194 57.7517L19.8827 64L29.4448 58.1709L29.5047 58.1909V46.5527Z"
          fill="url(#logo_paint4_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="logo_paint0_linear"
          x1="-7.25619"
          y1="38.2489"
          x2="26.9936"
          y2="18.4747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49A341" />
          <stop offset="0.4294" stopColor="#4BA543" />
          <stop offset="0.6118" stopColor="#52AA4A" />
          <stop offset="0.7474" stopColor="#5FB455" />
          <stop offset="0.8598" stopColor="#70C166" />
          <stop offset="0.9568" stopColor="#87D37B" />
          <stop offset="1" stopColor="#94DD87" />
        </linearGradient>
        <linearGradient
          id="logo_paint1_linear"
          x1="5.08586"
          y1="11.0459"
          x2="5.08586"
          y2="50.8171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9F99D" />
          <stop offset="0.0143" stopColor="#A3F498" />
          <stop offset="0.096" stopColor="#87DB7D" />
          <stop offset="0.1886" stopColor="#70C667" />
          <stop offset="0.2946" stopColor="#5FB656" />
          <stop offset="0.4226" stopColor="#52AB4A" />
          <stop offset="0.5948" stopColor="#4BA543" />
          <stop offset="1" stopColor="#49A341" />
        </linearGradient>
        <linearGradient
          id="logo_paint2_linear"
          x1="53.3629"
          y1="11.0459"
          x2="53.3629"
          y2="50.8171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9F99D" />
          <stop offset="0.0143" stopColor="#A3F498" />
          <stop offset="0.096" stopColor="#87DB7D" />
          <stop offset="0.1886" stopColor="#70C667" />
          <stop offset="0.2946" stopColor="#5FB656" />
          <stop offset="0.4226" stopColor="#52AB4A" />
          <stop offset="0.5948" stopColor="#4BA543" />
          <stop offset="1" stopColor="#49A341" />
        </linearGradient>
        <linearGradient
          id="logo_paint3_linear"
          x1="29.242"
          y1="8.47089"
          x2="18.7079"
          y2="8.47089"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94DD87" />
          <stop offset="0.0432" stopColor="#87D37B" />
          <stop offset="0.1402" stopColor="#70C166" />
          <stop offset="0.2526" stopColor="#5FB455" />
          <stop offset="0.3882" stopColor="#52AA4A" />
          <stop offset="0.5706" stopColor="#4BA543" />
          <stop offset="1" stopColor="#49A341" />
        </linearGradient>
        <linearGradient
          id="logo_paint4_linear"
          x1="29.4951"
          y1="55.2572"
          x2="11.011"
          y2="55.2572"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94DD87" />
          <stop offset="0.0469" stopColor="#84D178" />
          <stop offset="0.1306" stopColor="#6EC064" />
          <stop offset="0.2295" stopColor="#5DB354" />
          <stop offset="0.3519" stopColor="#52AA49" />
          <stop offset="0.5235" stopColor="#4BA443" />
          <stop offset="1" stopColor="#49A341" />
        </linearGradient>
        <clipPath id={`clip2021${id}`}>
          <rect width="58.4903" height="64" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;

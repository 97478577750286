import React from "react";
import {
  CardHeader,
  Card,
  CardBody,
  Text,
  Image,
  Stack,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { getFullDisplayBalance } from "src/utils/formatBalance";

export default function Item(props) {
  const { onItemClick, tokenId, selected, price, item } = props;
  return (
    <Card
      maxW="sm"
      style={{
        cursor: "pointer",
        outline: selected ? "2px solid #FFB524" : "",
      }}
      onClick={() => {
        onItemClick(tokenId);
      }}
      borderRadius="8"
    >
      <CardHeader padding={0}>
        {item.collection === "Normal" && (
          <Image src={item.tokenURI} borderTopRadius="8" />
        )}
        {item.collection === "V.I.P" && (
          <video src={item.tokenURI} autoPlay loop />
        )}
      </CardHeader>
      <CardBody padding="10px">
        <Stack>
          <Text
            color="#181F51"
            fontFamily="Poppins, sans-serif"
            fontWeight={600}
            fontSize="14px"
            lineHeight="21px"
            textAlign="center"
          >
            #{tokenId}
          </Text>
          <VStack mt="0 !important">
            <HStack w="100%" justifyContent="space-between">
              <Text
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="12px"
                lineHeight="21px"
                color="rgba(24, 31, 81, 0.4)"
              >
                Price
              </Text>
              <Text
                color="#181F51"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="12px"
                lineHeight="21px"
              >
                ${getFullDisplayBalance(price)}
              </Text>
            </HStack>
            <HStack w="100%" justifyContent="space-between" mt="0 !important">
              <Text
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="12px"
                lineHeight="21px"
                color="rgba(24, 31, 81, 0.4)"
              >
                APY
              </Text>
              <Text
                color="#181F51"
                fontFamily="Poppins, sans-serif"
                fontWeight={600}
                fontSize="12px"
                lineHeight="21px"
              >
                {item.collection === "Normal" ? "72%" : "96%"}
              </Text>
            </HStack>
          </VStack>
        </Stack>
      </CardBody>
    </Card>
  );
}

import React from "react";
import styled from "styled-components";
import Flex from "../../Box/Flex";
import { Box } from "../../Box";
import { ArrowBackIcon, CloseIcon } from "../../Svg";
import { IconButton } from "../../Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => background || "transparent"};
  border-bottom: 1px solid #e7e3eb;
  display: flex;
  padding: 12px 24px;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
`;

export const ModalCloseButton: React.FC<{
  onDismiss: ModalProps["onDismiss"];
}> = ({ onDismiss }) => {
  return (
    <IconButton
      variant="text"
      onClick={onDismiss}
      aria-label="Close the dialog"
    >
      <CloseIcon color="primary" />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({
  onBack,
}) => {
  return (
    <IconButton
      variant="text"
      onClick={onBack}
      area-label="go back"
      style={{ marginRight: "8px" }}
    >
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

export const ModalContainer = styled(Box)<{ minWidth: string }>`
  overflow: hidden;
  background: #faf9fa;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #e7e3eb;
  border-radius: 20px;
  width: 100%;
  max-height: 100vh;
  z-index: 9999;

  @media screen and (min-width: 370px) {
    width: auto;
    min-width: ${({ minWidth }) => minWidth};
    max-width: 100%;
  }
`;

import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { useWithdrawToken } from "src/hooks/stakeToken/useWithdrawToken";
import { toast } from "react-toastify";
import { useGetFreeInvestmentAmount } from "src/hooks/stakeToken/useGetFreeInvestmentAmount";

interface Props {
  id: number;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  title: string;
  staked: string;
  setReloadTime: any;
}

function CustomModal(props: Props) {
  const { id, isOpen, onClose, title, staked, setReloadTime } = props;
  const { onWithdrawToken } = useWithdrawToken();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const handleWithdraw = async () => {
    setRequestedApproval(true);
    try {
      await onWithdrawToken(id);
      setReloadTime(new Date().getTime());
    } catch (e) {
      console.log(e);
    }
    setRequestedApproval(false);
  };
  const freeInvestmentAmount = useGetFreeInvestmentAmount(id);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay
        background="rgba(24, 31, 81, 0.8)"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader
          fontWeight={700}
          fontSize="24px"
          lineHeight="33px"
          borderBottom="1px solid #EEEEEE"
          display="flex"
          alignItems="center"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="#FFB524" top="16px" />
        <ModalBody borderBottom="1px solid #EEEEEE">
          <div style={{ minHeight: "30px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Total staked:</div>
              <div>
                <b>{staked}</b> CLOUD
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Available withdrawal amount:</div>
              <div>
                <b>{freeInvestmentAmount.toNumber().toFixed(2)}</b> CLOUD
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          {!requestedApproval ? (
            <Button
              background="#FFB524 !important"
              w="100%"
              borderRadius="8px"
              onClick={handleWithdraw}
              fontWeight={700}
              fontSize={16}
              line-height="140%"
              isDisabled={freeInvestmentAmount.toNumber() <= 0}
            >
              Withdraw all
            </Button>
          ) : (
            <Button
              background="#EDF2F7 !important"
              w="100%"
              borderRadius="8px"
              fontWeight={700}
              fontSize={16}
              line-height="140%"
              cursor="default"
            >
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CustomModal;
